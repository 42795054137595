import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import shoorahLogo from '../../../assets/images/shoorah-logo.jpg';
import { on } from 'ws';

const HorizontalListCard = ({ title, onViewAllTap, dataList, onDropDownSelect }) => {
  const navigate = useNavigate();

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsShowDropdown(false);
    }
  };

  return (
    <div className="bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-[16px] mt-4 mb-[18px] px-4 lg:px-8 pt-4 pb-8">
      <div className="horizontal-card-header grid grid-cols-12 mb-6">
        <div className="col-span-7">
          <p className="lg:text-xl text-shoorah-textBlack">{title}</p>
        </div>
        <div className="col-span-5">
          <button className="text-[14px] text-shoorah-primary ms-auto w-full text-right" onClick={onViewAllTap}>
            View All
          </button>
        </div>
      </div>
      <div className="horizontal-card-body flex gap-4 overflow-x-auto horizontal-card-scrollbar pb-2">
        {dataList?.length === 0 ? (
          <div className="flex justify-center items-center h-full w-full">
            <p className="text-[20px] text-shoorah-customGray">No data available</p>
          </div>
        ) : (
          dataList?.slice(0,5)?.map((data, index) => (
            <div
              key={index}
              className="card-content grid grid-cols-12 border border-[#E7E8EA] rounded-xl p-4 max-w-[450px] h-[200px] gap-4 flex-shrink-0"
            >
              <div className="img-box rounded-xl col-span-5 max-h-[10rem]">
                <img
                  src={data?.coverImage ? data?.coverImage : shoorahLogo}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = shoorahLogo;
                  }}
                  alt="Template"
                  className="w-full h-full object-cover object-center rounded-xl"
                />
              </div>
              <div className="content-box col-span-7 h-full">
                <div className="dropdown-menu text-right relative" ref={dropdownRef}>
                  <button
                    onClick={() => [setIsShowDropdown(!isShowDropdown), setAssessmentId(data?.id)]}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  {isShowDropdown && assessmentId === data?.id && (
                    <ul className="w-auto h-fit overflow-y-auto rounded-xl z-10 bg-[#FFFFFF] border border-[#E7E8EA] ms-auto absolute top-full right-0">
                      <li
                        className="pl-4 pr-6 py-2 hover:bg-[#E7E8EA] cursor-pointer text-left capitalize"
                        onClick={() => {
                          onDropDownSelect(0, data?.id);
                          setIsShowDropdown(false);
                        }}
                      >
                        View
                      </li>
                      <li
                        className="pl-4 pr-6 py-2 hover:bg-[#E7E8EA] cursor-pointer text-left capitalize"
                        onClick={() => {
                          onDropDownSelect(1, data?.id);
                          setIsShowDropdown(false);
                        }}
                      >
                        delete
                      </li>
                    </ul>
                  )}
                </div>
                <p className="text-base text-[#000000] font-bold leading-snug whitespace-normal break-all">
                  {data?.title?.slice(0, 25)}
                </p>
                <p className="text-[16px] text-[#000000] font-thin mt-[10px]">Used 0 times</p>
                <button
                  className={`text-white font-thin w-[108px] text-[14px] mt-[10px] px-2 py-[6px] rounded-[10px] self-start bg-[#4A57DA]`}
                >
                  {data?.type === 1 ? 'Attachment' : 'Percentage'}
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HorizontalListCard;
