// Management.jsx
import React from 'react';
import classNames from 'classnames';

const ManagementTab = ({ PEAP_CONTENT, selectedContentType, setSelectedContentType }) => (
  <div className="w-full border-b-2 border-gray-200">
    <nav className="-mb-px flex space-x-8 pl-3 overflow-x-auto" aria-label="Tabs">
      {PEAP_CONTENT.map((tab) => (
        <div
          key={tab.name}
          onClick={() => {
            setSelectedContentType(tab.value);
          }}
          className={classNames(
            selectedContentType === tab.value
              ? 'text-gray border-shoorah-secondary dark:text-white'
              : 'border-transparent text-gray-500 opacity-50 hover:cursor-pointer hover:border-gray-100 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-2 py-2 text-sm font-medium dark:border-shoorah-darkBgColor'
          )}
        >
          {tab.name}
        </div>
      ))}
    </nav>
  </div>
);

export default ManagementTab;
