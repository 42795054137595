// import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import completeIcon from '../../assets/images/Complete.svg';
// import openIcon from '../../assets/images/Open.svg';
// import deliveredIcon from '../../assets/images/Delivered.svg';
// import incompleteIcon from '../../assets/images/InComplete.svg';
// import Carousel from '../../component/common/Carousel';
// import { getUserType, isSuperAdmin, isSuperAndSubAdmin } from '../../utils/helper';
// import { Api } from '../../api';
// import { SurveyContext } from '../../context/PreviewSurveyContext';
// import { getPathForSurvey } from '../../utils/helper';
// import { SURVEY_CONSTANT } from '../../core/web.constants';
// import DataFilter from './DataFilter';
// import { CompanyApi } from '../../api/companyApi';

// const PulseSurveyDashboard = () => {
//   const [refetch, setRefetch] = useState(false);
//   const [list, setList] = useState([]);
//   const [statistics, setStatistics] = useState({});
//   const { setSurveyMeta } = useContext(SurveyContext);
//   const [insightType, setInsightType] = useState('All');
//   const [templateType, setTemplateType] = useState('All');
//   const userData = JSON.parse(localStorage.getItem('userData'));
//   const userType = getUserType(userData?.userType);
//   const isUserSuperOrSubAdmin = ['Super Admin', 'Sub Admin'].includes(userType);

//   useEffect(() => {
//     setSurveyMeta(null);
//   }, []);

//   useEffect(() => {
//     const promise = isSuperAndSubAdmin()
//       ? Api.getPulseSurveys(1, 100, false, true)
//       : CompanyApi.getPulseSurveysB2b(1, 100, false, true);
//     promise
//       .then((response) => {
//         const responseData = response?.data?.data || [];
//         setList(responseData);
//       })
//       .catch((error) => {
//         console.error('API call error: ', error);
//       });
//   }, [refetch, templateType]);

//   // const getUrl = (url)=>{
//   // if(userData?.userType === 3 || userD)
//   // }

//   return (
//     <div className="xl:m-3 lg:p-3">
//       <div className="h-[15px] md:h-[50px] lg:h-[40px]" />
//       <div className="grid xl:grid-cols-2 gap-10">
//         <div className="grid grid-cols-1 gap-10">
//           <div className="bg-white dark:bg-shoorah-darkBgTabColor rounded-3xl p-5 shadow-lg">
//             <h1 className="text-xl font-semibold dark:text-white">
//               {SURVEY_CONSTANT.SURVEY_TITLE}
//             </h1>
//             <p className="text-xs dark:text-white">
//               <br />
//               {SURVEY_CONSTANT.SURVEY_HEADING_1}
//               <br />
//               <br /> {SURVEY_CONSTANT.SURVEY_HEADING_2}
//             </p>
//           </div>
//           <div className="bg-white rounded-3xl p-5 shadow-lg dark:bg-shoorah-darkBgTabColor">
//             <h1 className="text-2xl font-semibold dark:text-white">
//               {SURVEY_CONSTANT.NEW_SURVEY_CREATE_HEADING}
//             </h1>
//             <div className="grid xl:grid-cols-2 md:grid-cols-2  sm:grid-cols-1 p-5 gap-4 align-middle text-center">
//               <Link
//                 to={getPathForSurvey('/pulse-survey/add-edit-survey')}
//                 className="w-full p-5 bg-shoorah-secondary text-shoorah-offWhite rounded-3xl text-base dark:text-white"
//               >
//                 {SURVEY_CONSTANT.NEW_SURVEY_CREATE}
//               </Link>
//               <Link
//                 to={getPathForSurvey('/survey-list')}
//                 className="w-full p-5 bg-shoorah-navyblue rounded-3xl text-shoorah-offWhite text-base"
//               >
//                 {SURVEY_CONSTANT.MY_SURVEY_LIST_BUTTON}
//               </Link>
//             </div>
//             <Link
//               to={getPathForSurvey('/survey-list?listType=draft')}
//               className="text-shoorah-gray4 underline text-xs"
//             >
//               {SURVEY_CONSTANT.SURVEY_VIEW_DRAFTS_BUTTON}
//             </Link>
//           </div>
//         </div>
//         <div>
//           <div className="flex gap-2 items-center pb-5">
//             <h1 className="font-semibold text-xl dark:text-white">
//               {SURVEY_CONSTANT.SURVEY_INSIGHTS_HEADER}
//             </h1>
//             {isUserSuperOrSubAdmin && (
//               <>
//                 <div className="grow" />
//                 <div>
//                   <DataFilter onChange={setInsightType} />
//                 </div>
//               </>
//             )}
//           </div>
//           <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 ">
//             <div className="shadow-lg  bg-white rounded-3xl p-5 flex dark:bg-shoorah-darkBgTabColor">
//               <img src={completeIcon} className="h-20 w-20 p-3" />
//               <div className="p-3">
//                 <h1 className="font-extrabold text-2xl text-shoorah-primary dark:text-white">
//                   Total: {statistics?.complete || 0}
//                 </h1>
//                 <p className="text-sm text-shoorah-gray4 dark:text-white">Completed</p>
//               </div>
//             </div>
//             <div className="shadow-xl  bg-white rounded-3xl p-5 flex dark:bg-shoorah-darkBgTabColor">
//               <img src={incompleteIcon} className="h-20 w-20 p-3" alt="" />
//               <div className="p-3">
//                 <h1 className="font-extrabold text-2xl text-shoorah-darkRed dark:text-white ">
//                   Total: {statistics?.incomplete || 0}
//                 </h1>
//                 <p className="text-sm text-shoorah-gray4 dark:text-white">Incomplete</p>
//               </div>
//             </div>

//             <div className="shadow-xl  bg-white rounded-3xl p-5 flex dark:bg-shoorah-darkBgTabColor">
//               <img src={openIcon} className="h-20 w-20 p-3 dark:text-white" alt="" />
//               <div className="p-3">
//                 <h1 className="font-extrabold text-2xl text-shoorah-green dark:text-white">
//                   Total: {statistics?.open || 0}
//                 </h1>
//                 <p className="text-sm text-shoorah-gray4 dark:text-white">Currently Open</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {!isUserSuperOrSubAdmin && (
//         <>
//           <div className="pt-[40px]">
//             <div className="flex align-text-top justify-items-middle bg-white rounded-3xl p-5 shadow-lg dark:bg-shoorah-darkBgTabColor ">
//               <div>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   strokeWidth={1.5}
//                   stroke="currentColor"
//                   className="h-10 w-10"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     fill="#3A47AB"
//                     color="white"
//                     d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
//                   />
//                 </svg>
//               </div>
//               <div className="text-xs w-full flex justify-start items-center  dark:text-white ">
//                 {SURVEY_CONSTANT.SURVEY_DID_YOU_KNOW_TEXT}
//               </div>
//             </div>
//           </div>
//         </>
//       )}

//       <div className="pt-[40px]">
//         <div className="p-5 pb-10 rounded-3xl shadow-lg bg-white dark:bg-shoorah-darkBgTabColor">
//           <div className="flex items-center mb-5 w-[100%] justify-between">
//             <h1 className="font-semibold text-xl dark:text-white">
//               {SURVEY_CONSTANT.SURVEY_EXPLORE_TEMPS}
//             </h1>
//             <Link
//               to={getPathForSurvey('/survey-list?listType=template')}
//               className="w-fit py-1 px-2 bg-shoorah-secondary text-shoorah-offWhite rounded-xl text-sm dark:text-white"
//             >
//               {SURVEY_CONSTANT.VIEW_ALL_TEMPLATE}
//             </Link>
//           </div>
//           <Carousel data={list} onRefetch={() => setRefetch((prev) => !prev)} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PulseSurveyDashboard;

import Topbar from './Topbar';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Dots from '../../assets/images/Dots.svg';
import slack from '../../assets/images/slack.png';
import { getPathForSurvey } from '../../utils/helper';
import SearchInput from '../../component/common/Input/SearchInput';
import templatesurvey from '../../assets/images/templatesurvey.svg';
import templatesurveyA from '../../assets/images/templatesurveyA.svg';
import templatesurveyD from '../../assets/images/templatesurveyD.png';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import AddSurveyPopup from '../../component/common/modals/AddSurveyPopup';
import { surveyApi } from '../../api/surveyApi';

ChartJS.register(ArcElement, Tooltip, Legend);

const surveyData = [
  {
    id: '1',
    title: 'The Remote Bias',
    desc: '27 responses',
    img: slack
  },
  {
    id: '2',
    title: 'Company Wide Template',
    desc: '43 responses',
    img: templatesurvey
  },
  {
    id: '3',
    title: 'The Remote Bias',
    desc: '98 responses',
    img: slack
  }
];

const templateData = [
  {
    title: 'Time Specific Template',
    desc: 'Used 1,345 times',
    img: templatesurveyA
  },
  {
    title: 'Company Wide Template',
    desc: 'Used 1,003 times',
    img: templatesurveyD
  },
  {
    title: 'Company Wide Template',
    desc: 'Used 278 times',
    img: slack
  }
];

const PulseSurveyDashboard = () => {
  const [isOpen, setIsOpen] = useState(false); // Manage dropdown open/close state// Manage dropdown open/close state
  const [Id, setId] = useState('');
  const userData = localStorage.getItem('userData');
  const [isOpenNew, setIsOpenNew] = useState(false); // Manage dropdown open/close state
  const [survey, setSurvey] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Date Created'); // Manage selected option

  const [selectedOptionNew, setSelectedOptionNew] = useState('Create New Survey'); // Manage selected option

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle isOpen state
  };
  const toggleDropdownNew = () => {
    setIsOpenNew((prev) => !prev); // Toggle isOpen state
  };

  // Function to handle option click
  const handleOptionClick = (option) => {
    setSelectedOption(option); // Set the clicked option as selected
    setIsOpen(false); // Close the dropdown
  };
  const handleOptionClickNew = (option) => {
    setSelectedOptionNew(option); // Set the clicked option as selected
    setIsOpenNew(false); // Close the dropdown
    localStorage.setItem('surveyType', option);
    localStorage.removeItem('surveyId');
  };

  const handleQuestionAction = (id) => {
    if (Id === '') {
      setId(id);
    } else {
      setId('');
    }
  };

  const fetchData = async () => {
    try {
      await surveyApi
        .getSurveys(1, 6, '', '', '', 'createdAt')
        .then((data) => {
          setSurvey(data?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mx-auto max-w-[1640px] overflow-x-hidden hide-scrollbar font-work-sans">
      {userData.userType !== 4 && <Topbar />}
      <div className="pl-5 mt-[40px] font-work-sans">
        {/* Top buttons */}
        <div className="flex sm:justify-between items-center mt-[60px] sm:m-0">
          <SearchInput
            id="searchKey"
            name="searchKey"
            type="text"
            // onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
          <div className="flex items-center gap-3">
            <div className="flex justify-center items-center w-full sm:w-[170px] h-[40px] text-[14px] border border-[#0B1D2E] rounded-[100px]">
              <div className="relative inline-block text-left">
                {/* Button */}
                <button
                  type="button"
                  onClick={toggleDropdown} // Toggle dropdown on button click
                  className="inline-flex justify-between items-center w-full px-3 py-2 text-sm font-medium text-gray-700  focus:outline-none"
                >
                  {selectedOption} {/* Show selected option */}
                  <svg
                    className={`transform transtion-transform ml-0 sm:ml-3 ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    }`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.28 9.9668L12.9333 14.3135C12.42 14.8268 11.58 14.8268 11.0666 14.3135L6.71997 9.9668"
                      stroke="#0B1D2E"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                {/* Dropdown */}
                {isOpen && (
                  <div className="absolute left-[-12px] z-10 mt-1 w-[170px] bg-white divide-y divide-gray-100 rounded-md ring-1 ring-[#E7EAE8] ring-opacity-1">
                    <ul className="py-1 text-sm text-gray-700">
                      <li>
                        <button
                          onClick={() => handleOptionClick('Date Created')} // Handle option click
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          Date Created
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleOptionClick('Last Updated')} // Handle option click
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          Last Updated
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleOptionClick('Alphabetical')} // Handle option click
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          Alphabetical
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row relative justify-center items-center w-full sm:w-[195px] h-[40px] bg-[#4A57DA] text-[16px] rounded-[100px] text-[#F4F4F4]">
              <button
                className="flex flex-row items-center"
                type="button"
                onClick={toggleDropdownNew}
              >
                {selectedOptionNew}
              </button>
              <AddSurveyPopup
                open={isOpenNew}
                setOpen={toggleDropdownNew}
                getPathForSurvey={getPathForSurvey}
                handleOptionClickNew={handleOptionClickNew}
              />
            </div>
          </div>
        </div>

        {/* Slide */}
        <div className="flex sm:flex-row flex-col h-auto gap-4 mt-5">
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] border-[#E7E8EA] flex items-center pl-3">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#4A57DA" />
                  <path
                    d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.75 28L26.58 30.83L32.25 25.17"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Created Surveys</p>
                <p className="text-[24px] font-medium">1500</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#F05289" />
                  <path
                    d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.1699 30.83L30.8299 25.17"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M30.8299 30.83L25.1699 25.17"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Active Surveys</p>
                <p className="text-[24px] font-medium">245</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#0B1D2E" />
                  <path
                    d="M23.3999 22.32L31.8899 19.49C35.6999 18.22 37.7699 20.3 36.5099 24.11L33.6799 32.6C31.7799 38.31 28.6599 38.31 26.7599 32.6L25.9199 30.08L23.3999 29.24C17.6899 27.34 17.6899 24.23 23.3999 22.32Z"
                    stroke="#F4F4F4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M26.1101 29.6501L29.6901 26.0601"
                    stroke="#F4F4F4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Complete Surveys</p>
                <p className="text-[24px] font-medium">1,135</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#20BDAD" />
                  <path
                    d="M21.1499 18V38"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.1499 20H32.3499C35.0499 20 35.6499 21.5 33.7499 23.4L32.5499 24.6C31.7499 25.4 31.7499 26.7 32.5499 27.4L33.7499 28.6C35.6499 30.5 34.9499 32 32.3499 32H21.1499"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight"> Response Rate</p>
                <p className="text-[24px] font-medium">54%</p>
              </div>
            </div>
          </div>
        </div>
        {/* My Recent Surveys */}
        <div className="flex flex-col h-fit bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl my-5 p-6 pb-8">
          <div className="flex flex-row justify-between mb-8 items-center">
            <p className="text-[24px] text-shoorah-lightBlack leading-9 font-medium">
              My Recent Surveys
            </p>
            <Link
              to={getPathForSurvey('/pulse-survey/my-survey')}
              className="text-[14px] leading-9 font-medium text-[#4A57DA]"
            >
              View All
            </Link>
          </div>
          <div className="h-fit gap-4 grid grid-cols-3 bg-[#FFFFFF] border-[#E7E8EA] rounded-2xl">
            {survey?.slice(0, 3)?.map((item, index) => (
              <div
                className="w-full bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl"
                key={index}
              >
                <div className="relative">
                  <div className="bg-[#F4F4F4]">
                    <img src={item.logo} alt="survey" className="object-cover w-full h-[160px]" />
                  </div>
                  <div className="h-fit flex flex-col gap-4 px-4 py-6">
                    <div className="flex flex-row justify-between items-center bg-[#FFFFFF]">
                      <p className="text-[20px] text-shoorah-lightBlack font-bold leading-6">
                        {item.title}
                      </p>
                      <button className="relative" onClick={() => handleQuestionAction(item.id)}>
                        <img src={Dots} className="w-6 h-6 text-shoorah-lightBlack" />
                        {item.id === Id && (
                          <div
                            className="w-40 top-[13px] left-[-131px] z-10 bg-[#FFFFFF] absolute mt-2 border-[1.5px] py-[14px] border-[#DDDDDD] rounded-lg"
                            style={{
                              zIndex: 274
                            }}
                          >
                            <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                              Open
                            </div>
                            <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                              Edit
                            </div>
                            <div className="border-b border-gray-400 w-full mt-[15px] mb-2"></div>
                            <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                              Results
                            </div>
                            <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                              Rename
                            </div>
                            <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                              Duplicate
                            </div>
                            <div className="border-b border-gray-400 w-full mt-[15px] mb-2"></div>
                            <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer text-red-700 font-medium leading-6 text-[16px]">
                              Delete
                            </div>
                          </div>
                        )}
                      </button>
                    </div>
                    <div className="flex">
                      <p className="text-[16px] text-shoorah-lightBlack font-normal leading-5">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* My Template */}
        <div className="flex flex-col h-fit bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl my-5 p-6 pb-8">
          <div className="flex flex-row justify-between mb-8 items-center">
            <p className="text-[24px] text-shoorah-lightBlack leading-9 font-medium">
              Manage Templates
            </p>
            <Link
              to={getPathForSurvey('/pulse-survey/Mytemplatesviewall')}
              className="text-[14px] leading-9 font-medium text-[#4A57DA]"
            >
              View All
            </Link>
          </div>
          <div className="md:overflow-y-scroll hide-scrollbar h-fit gap-4 grid grid-cols-3 bg-[#FFFFFF] border-[#E7E8EA] rounded-2xl">
            {templateData.map((item, index) => (
              <div
                className="w-full bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl"
                key={index}
              >
                <div>
                  <div className="bg-[#F4F4F4]">
                    <img src={item.img} alt="survey" className="object-cover w-full h-[160px]" />
                  </div>
                  <div className="h-fit flex flex-col gap-4 px-4 py-6">
                    <p className="text-[20px] text-shoorah-lightBlack font-bold leading-6">
                      {item.title}
                    </p>
                    <div className="flex">
                      <p className="text-[16px] text-shoorah-lightBlack font-normal leading-5">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PulseSurveyDashboard;
