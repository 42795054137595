import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const ValueCards = () => {
  const dataD1 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [25, 35],
        backgroundColor: ['#D97F56', '#ECC09E'],
        hoverBackgroundColor: ['#D97F56', '#ECC09E'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const optionsD1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const optionsD2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD2 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [25, 25],
        backgroundColor: ['#67A14A', '#C2EC97'],
        hoverBackgroundColor: ['#67A14A', '#C2EC97'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD4 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD4 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [65, 35],
        backgroundColor: ['#21BDAD', '#6BE0BD'],
        hoverBackgroundColor: ['#21BDAD', '#6BE0BD'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };
  
  const dataD = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [1135, 365],
        backgroundColor: ['#ec4899', '#f9a8d4'],
        hoverBackgroundColor: ['#db2777', '#f472b6'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD5 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD5 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [45, 75],
        backgroundColor: ['#EDCC12', '#FFE873'],
        hoverBackgroundColor: ['#EDCC12', '#FFE873'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  return (
    <div className="flex flex-col sm:flex-row gap-4 w-full">
      <div className="flex flex-col lg:flex-row md:flex-col gap-10 w-full overflow-x-auto horizontal-card-scrollbar pb-2">
        <div className="md:flex md:flex-row gap-10">
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Complete Assessment
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm font-thin text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18]">0</p>
              </div>
              <Doughnut data={dataD1} options={optionsD1} />
            </div>
            <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-8 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#D97F56] rounded-full mr-2"></span>
                <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                  Complete: <p className="text-[#0B0F18] text-[20px]">0 </p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#ECC09E] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                  Incomplete: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0  md:w-1/2 lg:w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px] ">
                  User Age Range
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm font-thin text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD2} options={optionsD2} />
            </div>
            <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-8 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#67A14A] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                  Age 18-25: <p className="text-[#0B0F18] text-[20px] ">0</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#C2EC97] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                  Age 26-32: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex md:flex-row gap-10">
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Common Outcome
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm font-thin text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD4} options={optionsD4} />
            </div>
            <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-8 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#21BDAD] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                  Anxiety: <p className="text-[#0B0F18] text-[20px]">0 </p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#6BE0BD] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                  Depression: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Top Usage Times
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm font-thin text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD5} options={optionsD5} />
            </div>
            <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-8 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#EDCC12] rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                  9-10pm: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#FFE873] rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 text-[16px] items-center text-[#606060] font-thin">
                  7-8am: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex md:flex-row gap-10">
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 justify-between px-1">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Average Scores
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm font-thin text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD} options={optionsD} />
            </div>
            <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-8 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-pink-500 rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                  40-50%: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                  20-30%: <p className="text-[#0B0F18] items-center text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] lg:pl-[10px] md:pl-[0px]">
                  Complete Assessment
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-2xl text-[#0B0F18] ">0</p>
                <p className="text-sm font-thin text-[#606060]">Total</p>
              </div>
              <Doughnut data={dataD} options={optionsD} />
            </div>
            <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-8 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-pink-500 rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                  Responses: <p className="text-[#0B0F18]">0 </p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                  No Response: <p className="text-[#0B0F18]">0</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueCards;
