import axios from 'axios';
import { API_BASE_URL } from '../core/env.configs';
import {
  cleanLocalStorage,
  errorToast,
  getJWTToken,
  getLocalStorageItem,
  setLocalStorageItem
} from '../utils/helper';

const BASE_URL = API_BASE_URL || 'https://staging-api.shoorah.io/';

const GetApi = async (tag = '', isHeader = false) => {
  try {
    const data = await axios.get(BASE_URL + 'admin/v1' + tag, {
      headers: isHeader
        ? {
            Authorization: getJWTToken()
          }
        : {}
    });
    return data;
  } catch (e) {
    ErrorHandler(e);
  }
};

const DeleteApi = async (tag = '', isHeader = false) => {
  try {
    const data = await axios.delete(BASE_URL + 'admin/v1' + tag, {
      headers: isHeader
        ? {
            Authorization: getJWTToken()
          }
        : {}
    });
    return data;
  } catch (e) {
    ErrorHandler(e);
  }
};

const PostApi = async (tag = '', reqBody, isHeader = false, flag = false) => {
  let flagCheck = flag
    ? 'multipart/form-data; boundary=----WebKitFormBoundaryueI4YvrqiXxUgVGA'
    : 'application/json';

  try {
    const data = await axios.post(BASE_URL + 'admin/v1' + tag, reqBody, {
      headers: isHeader
        ? {
            'Content-Type': flagCheck,
            accept: flagCheck,
            Authorization: getJWTToken()
          }
        : {}
    });
    return data;
  } catch (e) {
    console.log('🚀 ~ PostApi ~ e:', e);
    ErrorHandler(e);
  }
};

const PutApi = async (tag = '', reqBody, isHeader = false, flag = false) => {
  let flagCheck = flag
    ? 'multipart/form-data; boundary=----WebKitFormBoundaryueI4YvrqiXxUgVGA'
    : 'application/json';

  try {
    const data = await axios.put(BASE_URL + 'admin/v1' + tag, reqBody, {
      headers: isHeader
        ? {
            'Content-Type': flagCheck,
            accept: flagCheck,
            Authorization: getJWTToken()
          }
        : {}
    });
    return data;
  } catch (e) {
    console.log('🚀 ~ PutApi ~ e:', e);
    ErrorHandler(e);
  }
};

const ErrorHandler = async (e) => {
  if (e.response?.data?.message) {
    if (e.response?.data?.code === 498) {
      RefreshToken();
    } else if (e.response?.data?.code === 401) {
      errorToast(e.response?.data?.message);
      cleanLocalStorage();
      window.location.href = '/login';
    } else {
      errorToast(e.response?.data?.message);
    }
  } else if (e.response?.data) {
    if (e.response?.data?.meta?.code === 498) {
      RefreshToken();
    } else if (e.response?.data?.code === 401) {
      errorToast(e.response?.data?.message);
      cleanLocalStorage();
      window.location.href = '/login';
    } else {
      errorToast(e.response?.data?.meta?.message);
    }
  } else {
    errorToast('Something went wrong');
  }
};

const RefreshToken = async () => {
  await axios
    .post(
      `${BASE_URL}/refresh-token`,
      {},
      { headers: { 'refresh-token': getLocalStorageItem('refreshToken') } }
    )
    .then(async (response) => {
      if (response.data.meta.code === 1) {
        setLocalStorageItem('token', response.data.meta.token);
        window.location.reload();
      }
    })
    .catch(async (error) => {
      if (error.response.data.code === 401) {
        cleanLocalStorage();
        window.location.href = '/login';
      } else {
        cleanLocalStorage();
        window.location.href = '/login';
      }
    });
};

export const surveyApi = {
  getSurveyCategory: () => GetApi('/survey/category', true),
  createSurveyByAdmin: (reqBody) => PostApi('/survey', reqBody, true, false),
  editSurveyByAdmin: (reqBody) => PutApi('/survey', reqBody, true, false),
  deleteSurvey: (id) => DeleteApi(`/survey?id=${id}`, true),
  getSurveys: (page, perPage, searchKey, filterQuery, sortBy, sortOrder, id) =>
    GetApi(
      `/survey?page=${page}&limit=${perPage}&search=${searchKey}${filterQuery}&id=${id}`,
      true
    ),
  getAdminList: (userType) =>
    GetApi(`/admins?userType=${userType}&accountStatus=1&page=1&perPage=10`, true)
};
