import { useState, useEffect } from 'react';
import { PEAP_CONTENT } from '../../../utils/constants';
import ManagementTab from './navigationTabs/management';
import { Helmet } from 'react-helmet';
import Complainant from './Complaint';
import Peapapproval from './Approvals';
import Insights from './insights';
import Messaging from './Messaging';
import PendingSpecialist from './pendingSpecialist';
import ActiveSpecialist from './activeSpecialist';

function Specialist() {
  const [selectedContentType, setSelectedContentType] = useState(1);
  const [today, setToday] = useState(new Date());
  const formattedDate = today.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  useEffect(() => {
    const timer = setInterval(() => {
      setToday(new Date());
    }, 100000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peap Management</title>
      </Helmet>
      <div className="block pl-6  mt-6 text-xl font-medium text-gray-800 dark:text-white md:text-3xl">
        PEAP Management
      </div>
      <div className="pl-6 pt-1 mb-6 text-gray-400">{formattedDate}</div>
      <div className="rounded-b-[10px] bg-shoorah-ggg pl-0 lg:pl-4 dark:bg-shoorah-darkBgColor dark:text-white">
        <div className="overflow-auto custom-scrollbar">
          <div className="w-full dark:border-shoorah-darkBgColor">
            <ManagementTab
              PEAP_CONTENT={PEAP_CONTENT}
              selectedContentType={selectedContentType}
              setSelectedContentType={setSelectedContentType}
            />
            {selectedContentType === 1 && <ActiveSpecialist />}
            {selectedContentType === 2 && <PendingSpecialist />}
            {selectedContentType === 3 && <Insights />}
            {selectedContentType === 4 && <Complainant />}
            {selectedContentType === 5 && <Messaging />}
            {selectedContentType === 6 && <Peapapproval />}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default Specialist;
