import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Fragment, useRef } from 'react';
import File from '../../../assets/images/File.svg';
import { Dialog, Transition } from '@headlessui/react';
import Calender from '../../../assets/images/Calender.svg';

const AddSurveyPopup = ({ open, setOpen, handleOptionClickNew, getPathForSurvey }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-shoorah-normanGray text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                <div className="bg-shoorah-normanGray p-8 sm:py-20 sm:px-[155px]">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left mb-16">
                    <Dialog.Title
                      as="h3"
                      className="text-[32px] font-medium leading-9 text-shoorah-lightBlack text-center"
                    >
                      How do you want to create your survey?
                    </Dialog.Title>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <button className="absolute right-5 top-5" onClick={() => setOpen(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path d="M 4.2382812 2.9882812 A 1.250125 1.250125 0 0 0 3.3671875 5.1347656 L 10.232422 12 L 3.3613281 18.869141 A 1.2512475 1.2512475 0 1 0 5.1308594 20.638672 L 12 13.767578 L 18.865234 20.632812 A 1.250125 1.250125 0 1 0 20.632812 18.865234 L 13.767578 12 L 20.625 5.1425781 A 1.250125 1.250125 0 1 0 18.857422 3.375 L 12 10.232422 L 5.1347656 3.3671875 A 1.250125 1.250125 0 0 0 4.2382812 2.9882812 z"></path>
                      </svg>
                    </button>
                    <Link
                      className="bg-white border-[1.5px] pb-6 px-11 pt-11 border-shoorah-borderGray rounded-2xl cursor-pointer"
                      to={getPathForSurvey('/pulse-survey/add-edit-survey')}
                      onClick={() => handleOptionClickNew('Survey')}
                    >
                      <div className="w-16 h-16 rounded-full bg-shoorah-lightBlue2 flex items-center justify-center mx-auto mb-6">
                        <img src={File} className="w-8 h-8" />
                      </div>
                      <h3 className="font-medium text-shoorah-lightBlack leading-9 text-2xl text-center mb-2">
                        Start from scratch
                      </h3>
                      <p className="text-shoorah-lightBlack font-normal text-[16px] leading-5 text-center">
                        Begin with a blank survey of form, then add your questions
                      </p>
                    </Link>
                    <Link className="bg-white border-[1.5px] pb-6 px-11 pt-11 border-shoorah-borderGray rounded-2xl cursor-pointer">
                      <div className="w-16 h-16 rounded-full bg-shoorah-lightBlue2 flex items-center justify-center mx-auto mb-6">
                        <img src={Calender} className="w-8 h-8" />
                      </div>
                      <h3 className="font-medium text-shoorah-lightBlack leading-9 text-2xl text-center mb-2">
                        Use a template
                      </h3>
                      <p className="text-shoorah-lightBlack font-normal text-[16px] leading-5 text-center">
                        Save time with a template built for your situation.
                      </p>
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

AddSurveyPopup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  getPathForSurvey: PropTypes.func,
  handleOptionClickNew: PropTypes.func
};

export default AddSurveyPopup;
