import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../component/common/Input/SearchInput';
import shoorahLogo from '../../assets/images/shoorah-logo.jpg';
import { Api } from '../../api';
import { Link } from 'react-router-dom';
import { getPathForSurvey } from '../../utils/helper';
import Topbar from '../../container/PulseSurvey/Topbar';
import { errorToast } from '../../utils/helper';
import { isSubAdmin } from '../../utils/helper';
const AllAssessment = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState();
  const [assessmentData, setAssessmentData] = useState([]);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [total, setTotal] = useState();
  const itemsPerPage = 12;

  const [assessmentType, setAssessmentType] = useState(6);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [selectedOptionNew, setSelectedOptionNew] = useState('New Assessment');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const assessmentStatusColor = {
    0: 'bg-[#4A57DA]',
    1: 'bg-[#67A14A]',
    2: 'bg-[#F05289]',
    3: 'bg-[#21BDAD]'
  };

  const getTypeColor = (index) => {
    return assessmentStatusColor[Math.floor(index / 3)];
  };

  const totalPages = Math.ceil(total / itemsPerPage);

  // Get All Assessment Data from API
  const getAssessmentData = () => {
    Api.getAssessment(currentPage, searchTerm, assessmentType, itemsPerPage).then((response) => {
      if (response?.data?.meta?.code === 1) {
        setAssessmentData(response?.data?.data);
        setCurrentPage(response?.data?.meta?.page || 1);
        setTotal(response?.data?.meta?.total);
      } else {
        setAssessmentData([]);
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteAssessment = (assessmentId) => {
    Api.deleteAssessment(assessmentId).then((response) => {
      if (response?.data?.meta?.code === 1) {
        getAssessmentData();
        setAssessmentId(null);
        setIsShowDropdown(null);
      }
    });
  };

  useEffect(() => {
    if (assessmentType !== null) {
      getAssessmentData();
    }
  }, [assessmentType]);

  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getVisiblePageNumbers = () => {
      if (totalPages <= 5) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      }

      if (currentPage <= 3) {
        return [1, 2, 3, 4, 5, '...'];
      }

      if (currentPage >= totalPages - 2) {
        return ['...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      }

      return [
        '...',
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
        '...'
      ];
    };

    const visiblePageNumbers = getVisiblePageNumbers();

    return (
      <div className="flex sm:justify-between justify-center w-full items-center m-4 gap-4">
        <div className="hidden sm:flex">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="w-[200px] h-[45px] border border-black rounded-full text-black bg-white hover:bg-gray-100 disabled:opacity-50 transition-all"
          >
            Previous page
          </button>
        </div>
        <div className="flex min-w-[254px] w-[25%] gap-4 justify-center">
          {visiblePageNumbers.map((number, index) => (
            <button
              key={index}
              onClick={() => typeof number === 'number' && onPageChange(number)}
              className={`px-[11.5px] py-2 rounded-full ${
                currentPage === number
                  ? 'bg-[#4A57DA]/20 text-[#4A57DA] scale-125 text-[20px] transition-all'
                  : 'text-gray-500 text-[20px] hover:bg-gray-200 hover:text-black transition-all scale-100'
              }`}
              disabled={typeof number !== 'number'}
            >
              {typeof number === 'number' ? (number < 10 ? `0${number}` : number) : '...'}
            </button>
          ))}
        </div>
        <div className="hidden sm:flex">
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="w-[200px] h-[45px] bg-[#4A57DA] text-white rounded-full hover:bg-[#3A45B0] disabled:opacity-50 transition-all"
          >
            Next Page
          </button>
        </div>
      </div>
    );
  };
  const onDropDownSelect = (index, id, selectIndex) => {
    if (id === null) {
      errorToast('Assessment ID not found');
      return;
    }
    switch (selectIndex) {
      case 0:
        navigate(`/self-assessment/create-self-assessment?view=4&id=${id}`);
        break;
      case 1:
        handleDeleteAssessment(id);
        break;
      case 2:
        console.log(assessmentData[index]);
        if (assessmentData[index].type === 1 ?? 3) {
          navigate(`/self-assessment/create-self-assessment?view=0&id=${id}&type=0`);
          break;
        }
        if (assessmentData[index].type === 0 ?? 3) {
          navigate(`/self-assessment/create-self-assessment?view=0&id=${id}&type=1`);
          break;
        }
        errorToast('Invalid Assessment Type');
        break;

      default:
        errorToast('Invalid action');
        break;
    }
  };

  const TemplateCard = ({ index, assessment }) => (
    <div className="card-content flex items-center border border-[#E7E8EA] rounded-xl p-4 gap-6">
      <div className="img-box rounded-xl overflow-hidden w-72 bg-red-300">
        <img
          src={assessment?.coverImage ? assessment?.coverImage : shoorahLogo}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = shoorahLogo;
          }}
          alt="Template"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="content-box w-full">
        <div className="dropdown-menu relative">
          <button
            className="flex items-center justify-end w-full"
            onClick={() => [setIsShowDropdown(!isShowDropdown), setAssessmentId(assessment?.id)]}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="#0B1D2E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                stroke="#0B1D2E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                stroke="#0B1D2E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {isShowDropdown && assessmentId === assessment?.id && (
            <ul class="w-auto h-fit overflow-y-auto rounded-xl  z-10 bg-[#FFFFFF] border border-[#E7E8EA] ms-auto absolute top-full right-0">
              {(assessmentType === 0 || assessmentType === 1) && (
                <li
                  class="pl-4 pr-6 py-2 hover:bg-[#E7E8EA] cursor-pointer text-left capitalize"
                  onClick={() => {
                    onDropDownSelect(index, assessment?.id, 2);
                    setIsShowDropdown(false);
                  }}
                >
                  Edit
                </li>
              )}
              {assessmentType != 0 && (
                <li
                  class="pl-4 pr-6 py-2 hover:bg-[#E7E8EA] cursor-pointer text-left capitalize"
                  onClick={() => {
                    onDropDownSelect(index, assessment?.id, 0);
                    setIsShowDropdown(false);
                  }}
                >
                  View
                </li>
              )}

              <li
                class="pl-4 pr-6 py-2 hover:bg-[#E7E8EA] cursor-pointer text-left capitalize"
                onClick={() => {
                  onDropDownSelect(index, assessment?.id, 1);
                  setIsShowDropdown(false);
                }}
              >
                delete
              </li>
            </ul>
          )}
        </div>
        <p className="text-[20px] text-[#000000] font-bold leading-snug whitespace-normal break-all">
          {assessment?.title}
        </p>
        <p className="text-[16px] text-[#000000] font-thin mt-[10px]">Used 0 times</p>
        <button
          className={`text-white font-thin w-[108px] text-[14px] mt-[10px] px-2 py-[6px] rounded-[10px] self-start ${getTypeColor(
            index
          )}`}
        >
          {assessment?.type === 1 ? 'Attachment' : 'Percentage'}
        </button>
      </div>
    </div>
  );
  const toggleDropdownNew = () => {
    setIsOpenNew((prev) => !prev);
  };
  const handleOptionClickNew = (option) => {
    setSelectedOptionNew(option);
    setIsOpenNew(false);
  };
  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
  };
  const handleFilterOption = (option) => {
    setAssessmentType(option);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    getAssessmentData();
  }, [currentPage, searchTerm]);

  return (
    <div className="mx-auto max-w-[1640px]">
      <div className="ml-5 mr-4  font-serif">
        <Topbar title="Self Assessment" />
        {/* search and new assessment */}

        <div className="flex flex-col sm:flex-row sm:justify-between justify-center mt-8 mb-4">
          <div className="flex flex-col sm:flex-row w-full sm:w-auto mb-4 sm:mb-0">
            <div className="w-full sm:w-auto">
              <SearchInput
                id="searchKey"
                name="searchKey"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search Assessments"
                className="w-full max-w-md border rounded-md p-2" // Adjust the max-w-md class as needed
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:justify-end w-full sm:w-auto">
            <div className="relative inline-block text-left mb-4 sm:mb-0 sm:mr-4">
              <button
                type="button"
                onClick={toggleFilter}
                className="inline-flex justify-between items-center w-full px-3 py-2 text-sm font-medium text-gray-700 border border-[#0B1D2E] rounded-[100px]"
              >
                {assessmentType === 0
                  ? 'Draft'
                  : assessmentType === 1
                  ? 'Inactive'
                  : assessmentType === 2
                  ? 'Active'
                  : assessmentType === 3
                  ? 'Send for Approval'
                  : assessmentType === 4
                  ? 'Rejected'
                  : assessmentType === 5
                  ? 'Deleted'
                  : assessmentType === 6
                  ? 'Published'
                  : 'Filter'}
                <svg
                  className={`transform transition-transform ml-0 sm:ml-3 ${
                    isFilterOpen ? 'rotate-180' : 'rotate-0'
                  }`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.28 9.9668L12.9333 14.3135C12.42 14.8268 11.58 14.8268 11.0666 14.3135L6.71997 9.9668"
                    stroke="#0B1D2E"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {isFilterOpen && (
                <div className="absolute left-[-12px] z-10 mt-1 w-[160px] bg-white divide-y divide-gray-100 rounded-md ring-1 ring-[#E7EAE8] ring-opacity-1">
                  <ul className="py-1 text-sm text-gray-700">
                    <li>
                      <button
                        onClick={() => handleFilterOption(0)}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        DRAFT
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleFilterOption(1)}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        INACTIVE
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleFilterOption(2)}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        ACTIVE
                      </button>
                    </li>
                    {isSubAdmin() && (
                      <li>
                        <button
                          onClick={() => handleFilterOption(3)}
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          SEND FOR APPROVAL
                        </button>
                      </li>
                    )}

                    <li>
                      <button
                        onClick={() => handleFilterOption(4)}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        REJECTED
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleFilterOption(5)}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        DELETED
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleFilterOption(6)}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        PUBLISHED
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="relative flex flex-row justify-center items-center w-full sm:w-[220px] h-[40px] bg-[#4A57DA] text-[16px] rounded-[100px] text-[#F4F4F4]">
              <button
                className="flex flex-row items-center"
                type="button"
                onClick={toggleDropdownNew}
              >
                {selectedOptionNew}
                <div className="ml-2">
                  <svg
                    className={`w-4 h-4 transform ${isOpenNew ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </button>
              {isOpenNew && (
                <div className="absolute top-[40px] md:top-[40px] lg:top-[40px] z-10 mt-1 w-[199px] md:lg:w-[220px] bg-white divide-y divide-gray-100 rounded-md ring-1 ring-[#E7EAE8] ring-opacity-1">
                  <ul className="py-1 text-sm text-gray-700">
                    <li>
                      <Link
                        to={getPathForSurvey(`/self-assessment/create-self-assessment?type=0`)}
                        onClick={() => handleOptionClickNew('attatchment')}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100 truncate"
                      >
                        Attachment Assessment
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={getPathForSurvey(`/self-assessment/create-self-assessment?type=1`)}
                        onClick={() => handleOptionClickNew('percentage')}
                        className="block px-4 py-2 w-full text-left truncate hover:bg-gray-100"
                      >
                        Percentage Assessment
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Cards list */}
        <div className="flex flex-col h-fit bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-[16px] mt-2 mb-4">
          <div className="h-[70px] flex flex-row justify-between mb-[18px] mt-1 mx-8 items-center">
            <p className="text-[24px] text-[#0B1D2E]">All Assessments</p>
            <button className="text-[14px] text-[#4A57DA]" onClick={() => handleFilterOption(0)}>
              View Drafts
            </button>
          </div>
          <div className="flex flex-col">
            {assessmentData === null || assessmentData?.length === 0 ? (
              <div className="grid grid-cols-1 gap-5 mx-6 mb-8">
                <h1 className="text-center text-[20px] text-shoorah-customGray">
                  No Assessment available
                </h1>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mx-6 mb-8">
                {assessmentData?.map((assessment, index) => (
                  <TemplateCard key={index} index={index} assessment={assessment} />
                ))}
              </div>
            )}
          </div>
        </div>
        {assessmentData?.length > 0 && (
          <div className="bottom-[-100px] mt-[20px] sticky w-[100%] h-[91px] rounded-[16px] align-middle overflow-hidden flex justify-center mx-0 border border-[#E7E8EA] bg-[#FFFFFF]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllAssessment;
