import { Api } from '../../api';
import { Link } from 'react-router-dom';
import { isSuperAdmin } from '../../utils/helper';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Topbar = ({ title }) => {
  const [companyData, setCompanyData] = useState({});
  const userData = JSON.parse(localStorage.getItem('userData'));
  const name = userData?.name?.split(' ');

  useEffect(() => {
    console.log(userData);
    if (userData?.userType === 3 || userData?.userType === 4) {
      Api.getCompanyData(userData?.companyId)
        .then((res) => {
          if (res.data?.meta?.code === 1) {
            const company = res.data?.data;
            setCompanyData({
              company_logo: company.company_logo,
              company_name: company.company_name,
              company_email: company.company_email,
              company_address: company.company_address,
              contact_person: company.contact_person,
              contact_number: company.contact_number,
              no_of_seat_bought: company.no_of_seat_bought,
              seat_price: company.seat_price,
              seat_active: company.seat_active,
              contract_progress: company.contract_progress,
              b2b_interest_via: company.b2b_interest_via,
              terms_agreed: company.terms_agreed,
              contract_sent: company.contract_sent,
              contract_signed: company.contract_signed,
              invoice_raised: company.invoice_raised,
              payment_complete: company.payment_complete,
              restrict_company: company.restrict_company,
              previousSeat: company.no_of_seat_bought,
              company_type: company.company_type,
              activeUsersCount: company.activeUsersCount,
              inactiveUsersCount: company.inactiveUsersCount
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div className="w-full  flex items-center justify-between gap-x-2 ">
      <h1 className="font-medium leading-9 text-shoorah-lightBlack text-xl flex items-center gap-2">
        {title}
      </h1>
      <div className="flex items-center gap-5">
        <div className="flex gap-x-2">
          <ReactTooltip id="notification-tip" />
          <div
            data-tooltip-id="notification-tip"
            data-tooltip-content="Notifications"
            className="w-10 h-10 flex items-center justify-center relative bg-white dark:bg-shoorah-darkBgTabColor rounded-full cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              data-slot="icon"
              className="w-6 h-6 stroke-shoorah-sidebarBackground dark:stroke-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-between items-start w-[250px] bg-white border px-6 py-3 rounded-xl">
          <div className="flex gap-4 items-center w-full relative">
            {companyData?.company_logo && !companyData.company_logo.includes('null') ? (
              <img
                loading="lazy"
                src={companyData.company_logo}
                className="w-12 aspect-square object-cover rounded-full bg-blue-300"
              />
            ) : (
              <div className="self-center dark:bg-shoorah-darkBgColor bg-black flex justify-center items-center w-12 aspect-square rounded-full">
                <span className="text-white">{name && name[0]?.charAt(0)}</span>
                <span className="text-white">{name && name[1]?.charAt(0)}</span>
              </div>
            )}
            <div>
              <span className="font-bold text-base leading-5">{userData?.name}</span>
              <div className="flex leading-none text-shoorah-gray3 gap-1 text-[12px] text-left font-medium">
                <h6 className="capitalize leading-[14px]">{userData?.companyName}</h6>
                <h6 className="leading-[14px]">
                  {userData?.userType === 0 || userData?.userType === 3
                    ? 'Super Admin'
                    : 'Sub Admin'}
                </h6>
              </div>
            </div>
          </div>
          <div className="text-base flex justify-center gap-x-1 items-center text-shoorah-newDashboardBlue2 dark:text-white">
            <Menu className="relative" as="div">
              <Menu.Button className="flex items-center justify-center text-sm font-medium focus:outline-none w-[18px] h-[18px] rounded-full border border-black mt-2">
                <ChevronDownIcon className="w-[14px] dark:text-white/80 text-black" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute shadow-lg bottom-0 left-[3rem] -translate-x-full translate-y-full z-10 mt-2  rounded-md dark:bg-shoorah-darkBgColor bg-white ring-1 ring-shoorah-primary ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={
                            !isSuperAdmin()
                              ? `/${userData.slug}/change-password`
                              : '/change-password'
                          }
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:text-white text-shoorah-sidebarBackground'
                              : ' dark:text-white text-gray-700',
                            'block px-4 py-2 whitespace-nowrap text-sm hover:bg-inherit'
                          )}
                        >
                          Change Password
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
