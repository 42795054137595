import validator from 'validator';

export const AddEditSurveyValidation = (data) => {
  const errors = {};

  if (validator.isEmpty(data?.title?.trim())) {
    errors.title = '*Survey title is required';
  }

  if (validator.isEmpty(data?.description?.trim())) {
    errors.description = '*Survey description is required';
  }

  if (!data?.type) {
    errors.type = '*Survey type is required';
  }

  if (validator.isEmpty(data?.category?.trim())) {
    errors.category = '*Survey category is required';
  }

  if (validator.isEmpty(data?.surveyQuestionTitle?.trim())) {
    errors.surveyQuestionTitle = '*Survey question title is required';
  }

  if (!data?.surveyQuestionType) {
    errors.surveyQuestionType = '*Survey question type is required';
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};

export const EditSurveyShareDataValidation = (data) => {
  const errors = {};

  if (!data?.audience) {
    errors.audience = '*Survey team is required';
  }

  if (validator.isEmpty(data?.endHeading.trim())) {
    errors.endHeading = '*Survey end heading is required';
  }

  if (validator.isEmpty(data?.endBody.trim())) {
    errors.endBody = '*Survey end body is required';
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};
