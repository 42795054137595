import React, { useState, useEffect } from 'react';
import { getPathForSurvey } from '../../utils/helper';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

import Topbar from '../../container/PulseSurvey/Topbar';
import { errorToast, successToast } from '../../utils/helper';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

import { Api } from '../../api';
import ValueCards from '../../component/common/cards/valueCard';
import HorizontalListCard from '../../component/common/cards/horizontalListCard';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SelfAssessmentDashboard = () => {
  const navigate = useNavigate();

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(74, 87, 218, 1)',
        borderColor: 'rgba(74, 87, 218, 1)',
        borderWidth: 1
      }
    ]
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Sales Data'
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Date Created');
  const [selectedOptionNew, setSelectedOptionNew] = useState('New Assessment');
  const [assessmentData, setAssessmentData] = useState([]);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };
  const toggleDropdownNew = () => {
    setIsOpenNew((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleOptionClickNew = (option) => {
    setSelectedOptionNew(option);
    setIsOpenNew(false);
  };

  const ProgressBar = ({ label, percentage }) => (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-1">
        <span className="text-lg font-normal">{label}</span>
        <span className="text-sm text-green-600">{percentage}%</span>
      </div>
      <div className="w-full bg-green-100 rounded-full h-2">
        <div className="bg-green-500 h-2 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );

  const handleDeleteAssessment = (assessmentId) => {
    Api.deleteAssessment(assessmentId)
      .then((response) => {
        console.log(response);
        if (response?.data?.meta?.code === 1) {
          getAssessmentData();
          setAssessmentId(null);
          setIsShowDropdown(null);
        }
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.message ?? 'Unknown Error Occurred');
      });
  };

  const getAssessmentData = () => {
    //fetch only published assessments whose status is 6
    Api.getAssessment(null, null, 6, null).then((response) => {
      if (response?.data?.meta?.code === 1) {
        setAssessmentData(response?.data?.data);
      }
    });
  };

  useEffect(() => {
    getAssessmentData();
  }, []);

  return (
    <div className="mx-auto lg:max-w-[1640px] ml-5 mr-5 mt-4 lg:mt-0 lg:mr-0">
      <Topbar title="Self Assessment" />
      <div className="mt-[40px]">
        <div className="flex flex-row gap-3 sm:justify-end items-center mt-[60px] sm:m-0">
          <div className="flex justify-center items-center w-full sm:w-[170px] h-[40px] text-xs lg:text-[16px] border border-[#0B1D2E] rounded-[100px]">
            <div className="relative inline-block text-left">
              <button type="button" onClick={toggleDropdown} className="flex flex-row items-center">
                {selectedOption}
                <svg
                  className={`transform transition-transform ml-0 sm:ml-3 ${
                    isOpen ? 'rotate-180' : 'rotate-0'
                  }`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.28 9.9668L12.9333 14.3135C12.42 14.8268 11.58 14.8268 11.0666 14.3135L6.71997 9.9668"
                    stroke="#0B1D2E"
                    strokeMiterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              {isOpen && (
                <div className="absolute left-[-12px] z-10 mt-1 w-[160px] bg-white divide-y divide-gray-100 rounded-md ring-1 ring-[#E7EAE8] ring-opacity-1">
                  <ul className="py-1 text-sm text-gray-700">
                    <li>
                      <button
                        onClick={() => handleOptionClick('Date Created')}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        Date Created
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleOptionClick('Last Updated')}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        Last Updated
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleOptionClick('Alphabetical')}
                        className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                      >
                        Alphabetical
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="relative flex flex-row justify-center items-center w-full sm:w-[220px] h-[40px] bg-[#4A57DA] text-xs lg:text-[16px] rounded-[100px] text-[#F4F4F4]">
            <button
              className="flex flex-row items-center"
              type="button"
              onClick={toggleDropdownNew}
            >
              {selectedOptionNew}
              <div className="ml-2">
                <svg
                  className={`w-4 h-4 transform ${isOpenNew ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </button>
            {isOpenNew && (
              <div className="absolute top-[40px] md:top-[40px] lg:top-[40px] z-10 mt-1 w-[199px] md:lg:w-[220px] bg-white divide-y divide-gray-100 rounded-md ring-1 ring-[#E7EAE8] ring-opacity-1">
                <ul className="py-1 text-sm text-gray-700">
                  <li>
                    <Link
                      to={getPathForSurvey(`/self-assessment/create-self-assessment?type=0`)}
                      onClick={() => handleOptionClickNew('attatchment')}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-100 truncate"
                    >
                      Attachment Assessment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={getPathForSurvey(`/self-assessment/create-self-assessment?type=1`)}
                      onClick={() => handleOptionClickNew('percentage')}
                      className="block px-4 py-2 w-full text-left truncate hover:bg-gray-100"
                    >
                      Percentage Assessment
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="flex sm:flex-row flex-col h-auto gap-5 mt-[40px]">
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] border-[#E7E8EA] flex items-center  pl-3  ">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#C2EC97" />
                  <path
                    d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.75 27.9999L26.58 30.8299L32.25 25.1699"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">
                  Completed <span>(month to date)</span>{' '}
                </p>
                <p className="text-[24px] font-medium">2,456</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#F05289" />
                  <path
                    d="M27.97 38C33.4928 38 37.97 33.5228 37.97 28C37.97 22.4772 33.4928 18 27.97 18C22.4471 18 17.97 22.4772 17.97 28C17.97 33.5228 22.4471 38 27.97 38Z"
                    stroke="white"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.21 32C24.26 33.51 26.02 34.5 28 34.5C29.98 34.5 31.73 33.51 32.79 32"
                    stroke="white"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Common Outcome</p>
                <p className="text-[24px] font-medium">Anxiety</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#EDCC12" />
                  <path
                    d="M36.75 29.25C36.75 34.08 32.83 38 28 38C23.17 38 19.25 34.08 19.25 29.25C19.25 24.42 23.17 20.5 28 20.5C32.83 20.5 36.75 24.42 36.75 29.25Z"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28 24V29"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25 18H31"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Average Time</p>
                <p className="text-[24px] font-medium">8 mins</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#20BDAD" />
                  <path
                    d="M34.32 28.0002C36.92 28.0002 38 27.0002 37.04 23.7202C36.39 21.5102 34.49 19.6102 32.28 18.9602C29 18.0002 28 19.0802 28 21.6802V24.5602C28 27.0002 29 28.0002 31 28.0002H34.32Z"
                    stroke="white"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M35.9999 30.6998C35.0699 35.3298 30.6299 38.6898 25.5799 37.8698C21.7899 37.2598 18.7399 34.2098 18.1199 30.4198C17.3099 25.3898 20.6499 20.9498 25.2599 20.0098"
                    stroke="white"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight"> Engagement Rate</p>
                <p className="text-[24px] font-medium">54%</p>
              </div>
            </div>
          </div>
        </div>
        <HorizontalListCard
          title="Manage Assessments"
          onViewAllTap={() => navigate('/self-assessment/view-all-assessment')}
          dataList={assessmentData}
          onDropDownSelect={(index, id) => {
            if (id === null) {
              errorToast('Assessment ID not found');
              return;
            }
            switch (index) {
              case 0:
                navigate(`/self-assessment/create-self-assessment?view=4&id=${id}`);
                break;
              case 1:
                handleDeleteAssessment(id);
                break;
              default:
                errorToast('Invalid action');
            }
          }}
        />
      </div>
      <div className="py-4 w-full h-fit mb-10">
        <ValueCards />
        <div className="flex flex-col lg:flex-row md:flex-col gap-6 sm:w-full mt-[20px]">
          <div className="flex flex-col justify-between w-full md:w-full lg:max-w-[265px] h-[200px] md:h-[200px] lg:h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
            <p className="text-[20px] lg:text-[20px] md:text-[25px] leading-tight h-[66px] font-medium w-[80%] justify-start text-[#0B0F18]">
              Percentage of incomplete Assessments
            </p>
            <p className="text-[64px] text-[#0B0F18] font-serif">0</p>
          </div>
          <div className="flex flex-col justify-between w-full md:w-full lg:max-w-[265px] h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
            <p className="text-[#0B0F18] text-[20px] lg:text-[20px] md:text-[25px]">
              Drop-off point
            </p>
            <div>
              <div className="max-w-md lg:max-w-md mx-auto p-6">
                <ProgressBar label="Intro" percentage={7} />
                <ProgressBar label="About" percentage={55} />
                <ProgressBar label="Questions" percentage={89} />
              </div>
            </div>
          </div>
          <div className="h-[344px] w-full bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-4">
            <Bar
              data={data}
              options={{
                ...options,
                responsive: true,
                maintainAspectRatio: false
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelfAssessmentDashboard;
