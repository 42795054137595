import axios from 'axios';
import Topbar from '../Topbar';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Calendar } from 'react-calendar';
import 'react-tooltip/dist/react-tooltip.css';
import { surveyApi } from '../../../api/surveyApi';
import React, { useEffect, useState } from 'react';
import slack from '../../../assets/images/slack.png';
import { CompanyApi } from '../../../api/companyApi';
import { errorToast, successToast } from '../../../utils/helper';
import {
  AddEditSurveyValidation,
  EditSurveyShareDataValidation
} from '../../../validation/AddEditSurveyValidation';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../api';

const AddEditPulseSurvey = () => {
  const location = new URLSearchParams(window.location.search);
  const id = location.get('id');
  const { userType } = JSON.parse(localStorage.getItem('userData'));
  const [addUserPopUpOpen, setAddUserPopUpOpen] = useState(false);
  const [addUserPopUpOpenSurvey, setAddUserPopUpOpenSurvey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const maxLength = 250;
  const [showCalendar, setShowCalendar] = useState(false);
  const [publishDate, setPublishDate] = useState('');
  const [isOpenT, setIsOpenT] = useState(false);
  const [isOpenA, setIsOpenA] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const surveyType = localStorage.getItem('surveyType');
  const surveyId = localStorage.getItem('surveyId');
  const [showPage, setShowPage] = useState('edit');
  const [errors, setErrors] = useState({});
  const [surveyCategory, setSurveyCategory] = useState([]);
  const [surveyData, setSurveyData] = useState({
    title: '',
    description: '',
    type: surveyType === 'Survey' ? 2 : surveyType === 'Template' ? 1 : 0,
    category: ''
  });
  const [surveyQuestionData, setSurveyQuestionData] = useState({
    surveyQuestionTitle: '',
    imgFiles: null,
    previewUrl: '',
    hasOther: false,
    skipable: false,
    nonAbove: false
  });
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [dropDownIndex, setDropDownIndex] = useState();
  const [isOpenQuestionType, setIsOpenQuestionType] = useState(false);
  const [surveyQuestionDataType, setSurveyQuestionDataType] = useState();
  const [surveyQuestionCount, setSurveyQuestionCount] = useState(1);
  const [answerChoices, setAnswerChoices] = useState([{ value: '' }]);
  const [bulkAnswer, setBulkAnswer] = useState([]);
  const [bulkAnswerInput, setBulkAnswerInput] = useState('');
  const [isOpenShareSurveyTeamMenu, setIsOpenShareSurveyTeamMenu] = useState(false);
  const [isShowSurveyQuestionDataObj, setIsShowSurveyQuestionDataObj] = useState(1);
  const [surveyQuestionDataObj, setSurveyQuestionDataObj] = useState([]);
  const [surveyShareData, setSurveyShareData] = useState({
    endHeading: '',
    endBody: '',
    audience: '',
    shareSurveyCategory: '',
    approvalBy: ''
  });
  const [surveyLogo, setSurveyLogo] = useState('');
  const [previewSurveyLogo, setPreviewSurveyLogo] = useState('');
  const [surveyQuestionImage, setSurveyQuestionImage] = useState({});
  // const [surveyQuestionPreview, setSurveyQuestionPreview] = useState({});
  const [tempImagePreview, setTempImagePreview] = useState('');
  // const [surveyQuestionImageIndex, setSurveyQuestionImageIndex] = useState(1);
  const [surveyCoverImage, setSurveyCoverImage] = useState('');
  const [coverImg, setCoverImgUrl] = useState('');
  const [isShowSurveyApprovalModal, setIsShowSurveyApprovalModal] = useState(false);
  const [isShowSurveySuccessModal, setIsShowSurveySuccessModal] = useState(false);
  const [superAdminList, setSuperAdminList] = useState([]);
  const shareSurveyTeamOptions = ['IT', 'Marketing', 'Customer Service'];
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState([]);
  const [isShowBulkAnswer, setIsShowBulkAnswer] = useState(false);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  const userData = localStorage.getItem('userData');

  const toggleDropdown = (index) => {
    setIsOpen(!isOpen);
    setDropDownIndex(index);
  };

  const handleToggleCalendar = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };

  const handleChangeC = (e) => {
    const date = new Date(e);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setPublishDate(formattedDate);
    setShowCalendar(false);
  };

  const generateTimes = () => {
    const times = [];
    const start = 0;
    const end = 12 * 60;
    for (let time = start; time <= end; time += 30) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      const period = hours >= 12 ? 'pm' : 'am';
      const displayTime = `${hours % 12 || 12}:${minutes === 0 ? '00' : minutes} ${period}`;
      times.push(displayTime);
    }
    return times;
  };

  const handleSelectT = (time) => {
    setSelectedTime(time);
    setIsOpenT(false);
  };

  const handleSelectA = (area) => {
    setSelectedArea(area);
    setIsOpenA(false);
  };

  const convertToISO = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString.replace('AM', ' AM').replace('PM', ' PM');
    const date = new Date(formattedDateTimeString);
    const isoDate = date.toISOString();
    return isoDate;
  };

  const handleGetSurveyCategoryData = async () => {
    userType === 0 || userType === 1
      ? surveyApi.getSurveyCategory().then((response) => {
          if (response?.data?.meta?.code === 1) {
            setSurveyCategory(response?.data?.data);
          }
        })
      : CompanyApi.getSurveyCategoryCompany().then((response) => {
          if (response?.data?.meta?.code === 1) {
            setSurveyCategory(response?.data?.data);
          }
        });
  };

  const options = surveyCategory.map((surveyCategory) => {
    return {
      value: surveyCategory?._id,
      label: surveyCategory?.name?.charAt(0)?.toUpperCase() + surveyCategory?.name?.slice(1)
    };
  });

  const handleGetSurveyData = (e) => {
    setSurveyData({ ...surveyData, [e.target.name]: e.target.value });
  };

  const handleGetQuestionData = (e) => {
    setSurveyQuestionData({ ...surveyQuestionData, [e.target.name]: e.target.value });
  };

  const handleGetSurveyCategory = (e) => {
    setSurveyData({ ...surveyData, category: e.value });
  };

  const handleUploadSurveyLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSurveyLogo(file);
      setPreviewSurveyLogo(URL.createObjectURL(file));
    }
  };

  const handleUploadQuestionImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSurveyQuestionData({
        ...surveyQuestionData,
        imgFiles: file,
        previewUrl: URL.createObjectURL(file)
      });
      // setSurveyQuestionImage({
      //   ...surveyQuestionImage,
      //   [`ques${surveyQuestionImageIndex}Image`]: file
      // });
      // setSurveyQuestionPreview({
      //   ...surveyQuestionPreview,
      //   [`ques${surveyQuestionImageIndex}Image`]: URL.createObjectURL(file)
      // });
      setTempImagePreview(URL.createObjectURL(file));
    }
  };

  const handleGetQueAns = (index, e, type) => {
    if (type === 'bulk') {
      const newBulkAnswer = [...bulkAnswer];
      newBulkAnswer[index] = e.target.value;
      setBulkAnswer(newBulkAnswer);
    } else {
      const newAnswerChoices = [...answerChoices];
      newAnswerChoices[index].value = e.target.value;
      setAnswerChoices(newAnswerChoices);
    }
  };

  const handleEmailChange = (e) => {
    const input = e.target.value;
    setEmailInput(input);
    const emailArray = input
      .split(/[\n,]+/)
      .map((email) => email.trim())
      .filter((email) => email);
    setEmails(emailArray);
  };

  const removeAnswerField = (index, type) => {
    if (type === 'choice') {
      const newAnswerChoices = answerChoices.filter((_, i) => i !== index);
      setAnswerChoices(newAnswerChoices);
      setSurveyQuestionCount(surveyQuestionCount - 1);
    } else {
      const newAnswerChoices = bulkAnswer.filter((_, i) => i !== index);
      setBulkAnswer(newAnswerChoices);
      setSurveyQuestionCount(surveyQuestionCount - 1);
    }
  };

  const addAnswerField = (type) => {
    if (type === 'choice') {
      setAnswerChoices([...answerChoices, { value: '' }]);
      setSurveyQuestionCount(surveyQuestionCount + 1);
    } else {
      setBulkAnswer([...bulkAnswer, '']);
      setSurveyQuestionCount(surveyQuestionCount + 1);
    }
  };

  // Save All Question in Local Storage
  const saveSurveyQuestion = () => {
    setSurveyData((prev) => {
      return {
        ...prev,
        type: surveyType === 'Survey' ? 2 : surveyType === 'Template' ? 1 : 0
      };
    });
    const dataObj = {
      ...surveyData,
      surveyQuestionType: surveyQuestionDataType,
      ...surveyQuestionData
    };

    let { errors, isValid } = AddEditSurveyValidation(dataObj);

    const hasValidOption = answerChoices.some((obj) => obj.value === '');

    if (hasValidOption && surveyQuestionDataType !== 3) {
      setErrors({ ...errors, options: '*At least one option is required' });
    }

    if (!isValid && hasValidOption) {
      setErrors(errors);
    } else {
      setErrors('');
      const saveQuestion = {
        title: surveyQuestionData.surveyQuestionTitle,
        qType: surveyQuestionDataType,
        options: bulkAnswer.length === 0 ? answerChoices : bulkAnswer.map((i) => ({ value: i })),
        hasOther: surveyQuestionData.hasOther,
        skipable: surveyQuestionData.skipable,
        nonAbove: surveyQuestionData.nonAbove,
        imgFiles: surveyQuestionData.imgFiles,
        previewUrl: surveyQuestionData.previewUrl
      };

      // const newSurveyQuestionDataObj = {
      //   ...surveyData,
      //   saveQuestion
      // };
      // let existingSurveyQuestionData =
      //   JSON.parse(localStorage.getItem('surveyQuestionDataObj')) || [];

      if (editQuestionIndex === null) {
        // if (existingSurveyQuestionData.length > 0) {
        //   if (existingSurveyQuestionData[0].saveQuestionsArray?.length > 0) {
        //     existingSurveyQuestionData[0].saveQuestionsArray.push(saveQuestionsArray[0]);
        //   } else {
        //     existingSurveyQuestionData[0].saveQuestionsArray = [saveQuestionsArray];
        //   }
        //   localStorage.setItem('surveyQuestionDataObj', JSON.stringify(existingSurveyQuestionData));
        // } else {
        //   existingSurveyQuestionData.push(newSurveyQuestionDataObj);
        //   localStorage.setItem('surveyQuestionDataObj', JSON.stringify(existingSurveyQuestionData));
        // }

        setSurveyQuestions((prev) => [...prev, saveQuestion]);
        successToast('Question is saved');
        setIsShowSurveyQuestionDataObj(isShowSurveyQuestionDataObj + 1);
        setSurveyQuestionData({
          surveyQuestionTitle: '',
          hasOther: false,
          skipable: false,
          nonAbove: false,
          imgFiles: null,
          previewUrl: ''
        });
        setAnswerChoices([{ value: '' }]);
        setTempImagePreview('');
        setSurveyQuestionDataType();
        // setSurveyQuestionImageIndex(surveyQuestionImageIndex + 1);
      } else {
        // const newSurveyQuestionDataObj = {
        //   ...surveyData,
        //   saveQuestionsArray: [
        //     {
        //       title: surveyQuestionData.surveyQuestionTitle,
        //       qType: surveyQuestionDataType,
        //       options:
        //         bulkAnswer.length === 0 ? answerChoices : bulkAnswer.map((i) => ({ value: i })),
        //       hasOther: surveyQuestionData.hasOther,
        //       skipable: surveyQuestionData.skipable
        //     }
        //   ]
        // };
        // existingSurveyQuestionData[0].saveQuestionsArray[editQuestionIndex] =
        //   newSurveyQuestionDataObj.saveQuestionsArray[0];
        // localStorage.setItem('surveyQuestionDataObj', JSON.stringify(existingSurveyQuestionData));
        const saveQuestion = {
          title: surveyQuestionData.surveyQuestionTitle,
          qType: surveyQuestionDataType,
          options: bulkAnswer.length === 0 ? answerChoices : bulkAnswer.map((i) => ({ value: i })),
          hasOther: surveyQuestionData.hasOther,
          skipable: surveyQuestionData.skipable,
          nonAbove: surveyQuestionData.nonAbove,
          imgFiles: surveyQuestionData.imgFiles,
          previewUrl: surveyQuestionData.previewUrl
        };
        setSurveyQuestions((prev) => [...prev, saveQuestion]);
        successToast('Question is updated');
        setIsShowSurveyQuestionDataObj(isShowSurveyQuestionDataObj + 1);
        setSurveyQuestionData({
          surveyQuestionTitle: '',
          hasOther: false,
          skipable: false,
          nonAbove: false,
          imgFiles: null,
          previewUrl: ''
        });
        setAnswerChoices([{ value: '' }]);
        setTempImagePreview('');
        setSurveyQuestionDataType();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && bulkAnswerInput.trim() !== '') {
      const lines = bulkAnswerInput.split('\n').pop();
      setBulkAnswer([...bulkAnswer, lines]);
    }
  };

  const handleSaveBulkAnswer = () => {
    if (bulkAnswerInput) {
      setBulkAnswer([...bulkAnswer, bulkAnswerInput.trim().split('\n').pop()]);
      localStorage.setItem('bulkAnswer', [...bulkAnswer, bulkAnswerInput.trim().split('\n').pop()]);
      setAddUserPopUpOpen(false);
      setIsShowBulkAnswer(true);
      setBulkAnswerInput('');
    }
  };

  const uploadImage = async (imageFile, uploadURL, fileName) => {
    try {
      const formData = new FormData();
      formData.append(fileName, imageFile);

      const response = await axios.put(uploadURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        return response;
      } else {
        throw new Error('Upload failed with status: ' + response);
      }
    } catch (error) {
      return error;
    }
  };

  // Redirect to the Preview page from Edit Page
  const handlePublishSurvey = (type) => {
    surveyData?.title === ''
      ? setErrors((prev) => {
          return {
            ...prev,
            title: '*Survey title is required'
          };
        })
      : setErrors((prev) => {
          return {
            ...prev,
            title: ''
          };
        });
    surveyData?.description === ''
      ? setErrors((prev) => {
          return {
            ...prev,
            description: '*Survey description is required'
          };
        })
      : setErrors((prev) => {
          return {
            ...prev,
            description: ''
          };
        });

    surveyData?.category === ''
      ? setErrors((prev) => {
          return {
            ...prev,
            category: '*Survey category is required'
          };
        })
      : setErrors((prev) => {
          return {
            ...prev,
            category: ''
          };
        });
    surveyQuestions?.length === 0
      ? setErrors((prev) => {
          return {
            ...prev,
            questions: '*At least one question is required'
          };
        })
      : setErrors((prev) => {
          return {
            ...prev,
            questions: ''
          };
        });

    if (
      surveyData?.title &&
      surveyData?.description &&
      surveyData?.category &&
      surveyQuestions?.length > 0
    ) {
      type === 'publish' || type === 'draft' ? setShowPage('preview') : setShowPage(type);
    }

    // type === 'draft' && localStorage.setItem('surveyType', type);
    // let surveyQuestionDataObj = JSON.parse(localStorage.getItem('surveyQuestionDataObj'));
    // setSurveyQuestionDataObj(surveyQuestionDataObj || []);
  };

  // Redirect to the Share page from Preview Page With Create Survey API
  const handleSubmitSurvey = () => {
    const sendDataObj = {
      title: surveyData.title,
      description: surveyData.description,
      type: localStorage.getItem('surveyType') === 'draft' ? 0 : surveyData.type,
      category: surveyData.category,
      logo: surveyLogo.type,
      questions: surveyQuestions?.map((i) => {
        return {
          title: i?.title,
          qType: i?.qType,
          options: i?.options,
          hasOther: i?.hasOther,
          skipable: i?.skipable,
          image: i?.imgFiles?.type && i?.imgFiles?.type
        };
      })
    };

    userType === 0 || userType === 1
      ? surveyApi.createSurveyByAdmin(sendDataObj).then(async (response) => {
          if (response?.data?.data?.id) {
            localStorage.setItem('surveyId', response?.data?.data?.id);
            // navigate('/pulse-survey/add-edit-survey?id=' + response?.data?.data?.id);
          }
          if (response?.data?.meta?.code === 1) {
            const meta = response.data.meta;
            const uploadPromises = [];

            if (meta?.logo && meta?.logo?.uploadURL) {
              uploadPromises.push(
                uploadImage(surveyLogo, meta?.logo?.uploadURL, meta?.logo?.filename)
              );
            }

            for (const key in meta) {
              if (key.startsWith('ques') && key.endsWith('Image')) {
                const uploadPromise = uploadImage(
                  surveyQuestions[key]?.imgFiles,
                  meta[key]?.uploadURL,
                  meta[key]?.filename
                );
                uploadPromises.push(uploadPromise);
              }
            }

            try {
              const results = await Promise.all(uploadPromises);
              if (results.every((result) => result.status === 200)) {
                successToast('All images uploaded successfully');
                setShowPage('share');
              } else {
                console.error('Some images failed to upload:', results);
              }
            } catch (error) {
              console.error('Error during uploads:', error);
              errorToast(error);
            }
          }
        })
      : CompanyApi.createSurveyCompany(sendDataObj).then(async (response) => {
          if (response?.data?.data?.id) {
            localStorage.setItem('surveyId', response?.data?.data?.id);
          }
          if (response?.data?.meta?.code === 1) {
            const meta = response.data.meta;
            const uploadPromises = [];

            if (meta?.logo && meta?.logo?.uploadURL) {
              uploadPromises.push(
                uploadImage(surveyLogo, meta?.logo?.uploadURL, meta?.logo?.filename)
              );
            }

            for (const key in meta) {
              if (key.startsWith('ques') && key.endsWith('Image')) {
                const uploadPromise = uploadImage(
                  surveyQuestionImage[key],
                  meta[key]?.uploadURL,
                  meta[key]?.filename
                );
                uploadPromises.push(uploadPromise);
              }
            }

            try {
              const results = await Promise.all(uploadPromises);
              if (results.every((result) => result.status === 200)) {
                successToast('All images uploaded successfully');
                setShowPage('share');
              } else {
                console.error('Some images failed to upload:', results);
              }
            } catch (error) {
              console.error('Error during uploads:', error);
              errorToast(error);
            }
          }
        });
  };

  const handleGetEndPageData = ({ target: { name, value } }) => {
    setSurveyShareData({ ...surveyShareData, [name]: value });
  };

  // Send Survey data from Share Page with Edit Survey API
  const handleSendSurvey = () => {
    if (userType === 0 || userType === 1) {
      if (userType === 0) {
        const { errors, isValid } = EditSurveyShareDataValidation({
          audience: surveyShareData.audience,
          endHeading: surveyShareData.endHeading,
          endBody: surveyShareData.endBody
        });
        if (!isValid) {
          setErrors(errors);
        } else {
          let sendDataObj = {
            id: localStorage.getItem('surveyId'),
            audience: surveyShareData.audience,
            ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
            publishDateTime:
              publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
            endHeading: surveyShareData.endHeading,
            endBody: surveyShareData.endBody,
            ...(surveyData.category && { category: surveyData.category })
          };
          surveyApi.editSurveyByAdmin(sendDataObj).then(async (response) => {
            if (response?.data?.meta?.code === 1) {
              const meta = response.data.meta;
              setSurveyShareData({
                audience: '',
                endBody: '',
                endHeading: '',
                shareSurveyCategory: ''
              });
              setSurveyCoverImage('');
              setIsShowSurveySuccessModal(true);
              if (meta?.coverImage && meta?.coverImage?.uploadURL) {
                const uploadImageResponse = await uploadImage(
                  surveyCoverImage,
                  meta?.coverImage?.uploadURL,
                  meta?.coverImage?.filename
                );
                if (uploadImageResponse?.status === 200) {
                  successToast('Image uploaded successfully');
                  setIsShowSurveySuccessModal(true);
                  setTimeout(() => {
                    window.location.href = '/pulse-survey';
                    setIsShowSurveySuccessModal(false);
                  }, 1200);
                }
              }
              localStorage.removeItem('surveyId');
              setTimeout(() => {
                window.location.href = '/pulse-survey';
                setIsShowSurveySuccessModal(false);
              }, 1200);
            }
          });
          setErrors('');
        }
      } else {
        setIsShowSurveyApprovalModal(!isShowSurveyApprovalModal);
        surveyApi.getAdminList(0).then(async (response) => {
          if (response?.data?.meta?.code === 1) {
            setSuperAdminList(response?.data?.data);
          }
        });
      }
    } else {
      if (userType === 3 || userType === 4) {
        if (userType === 3) {
          const { errors, isValid } = EditSurveyShareDataValidation({
            audience: surveyShareData.audience,
            endHeading: surveyShareData.endHeading,
            endBody: surveyShareData.endBody
          });
          if (!isValid) {
            setErrors(errors);
          } else {
            let sendDataObj = {
              id: localStorage.getItem('surveyId'),
              department: surveyShareData.audience,
              ...(emails.length > 0 && { emails: emails }),
              ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
              publishDateTime:
                publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
              endHeading: surveyShareData.endHeading,
              endBody: surveyShareData.endBody
            };
            CompanyApi.editSurveyCompany(sendDataObj).then(async (response) => {
              if (response?.data?.meta?.code === 1) {
                const meta = response.data.meta;
                setSurveyShareData({
                  audience: '',
                  endBody: '',
                  endHeading: '',
                  shareSurveyCategory: ''
                });
                setEmailInput('');
                setEmails('');
                setSurveyCoverImage('');
                setIsShowSurveySuccessModal(true);
                if (meta?.coverImage && meta?.coverImage?.uploadURL) {
                  const uploadImageResponse = await uploadImage(
                    surveyCoverImage,
                    meta?.coverImage?.uploadURL,
                    meta?.coverImage?.filename
                  );
                  if (uploadImageResponse?.status === 200) {
                    successToast('Image uploaded successfully');
                    setIsShowSurveySuccessModal(true);
                    setTimeout(() => {
                      window.location.href = '/reckit/pulse-survey';
                      setIsShowSurveySuccessModal(false);
                    }, 1200);
                  }
                }
                setTimeout(() => {
                  window.location.href = '/reckit/pulse-survey';
                  setIsShowSurveySuccessModal(false);
                }, 1200);
              }
            });
            setErrors('');
          }
        } else {
          setIsShowSurveyApprovalModal(!isShowSurveyApprovalModal);
          CompanyApi.getAdminListCompany(3).then(async (response) => {
            if (response?.data?.meta?.code === 1) {
              setSuperAdminList(response?.data?.data);
            }
          });
        }
      }
    }
  };

  const superAdminListOptions = superAdminList.map((admin) => {
    return {
      value: admin?.id,
      label: admin?.name?.charAt(0)?.toUpperCase() + admin?.name?.slice(1)
    };
  });

  const handleSendSurveyWithApproval = () => {
    setIsShowSurveyApprovalModal(false);
    const { errors, isValid } = EditSurveyShareDataValidation({
      audience: surveyShareData.audience,
      endHeading: surveyShareData.endHeading,
      endBody: surveyShareData.endBody
    });
    if (userType === 1) {
      if (!isValid) {
        setErrors(errors);
      } else {
        let sendDataObj = {
          id: localStorage.getItem('surveyId'),
          audience: surveyShareData.audience,
          ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
          publishDateTime:
            publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
          endHeading: surveyShareData.endHeading,
          endBody: surveyShareData.endBody,
          approvalBy: surveyShareData.approvalBy
        };

        surveyApi.editSurveyByAdmin(sendDataObj).then(async (response) => {
          if (response?.data?.meta?.code === 1) {
            const meta = response.data.meta;
            setSurveyShareData({
              audience: '',
              endBody: '',
              endHeading: '',
              shareSurveyCategory: ''
            });
            setSurveyCoverImage('');
            setIsShowSurveyApprovalModal(false);
            setIsShowSurveySuccessModal(true);
            setTimeout(() => {
              window.location.href = '/pulse-survey';
              setIsShowSurveySuccessModal(false);
            }, 1200);
            if (meta?.coverImage && meta?.coverImage?.uploadURL) {
              const uploadImageResponse = await uploadImage(
                surveyCoverImage,
                meta?.coverImage?.uploadURL,
                meta?.coverImage?.filename
              );
              if (uploadImageResponse?.status === 200) {
                successToast('Image uploaded successfully');
                setIsShowSurveySuccessModal(true);
                setTimeout(() => {
                  window.location.href = '/pulse-survey';
                  setIsShowSurveySuccessModal(false);
                }, 1200);
              }
            }
          }
        });
        setErrors('');
      }
    } else {
      if (!isValid) {
        setErrors(errors);
      } else {
        let sendDataObj = {
          id: localStorage.getItem('surveyId'),
          department: surveyShareData.audience,
          ...(emails.length > 0 && { emails: emails }),
          ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
          publishDateTime:
            publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
          endHeading: surveyShareData.endHeading,
          endBody: surveyShareData.endBody,
          approvalBy: surveyShareData.approvalBy
        };
        CompanyApi.editSurveyCompany(sendDataObj).then(async (response) => {
          if (response?.data?.meta?.code === 1) {
            const meta = response.data.meta;
            setSurveyShareData({
              audience: '',
              endBody: '',
              endHeading: '',
              shareSurveyCategory: ''
            });
            setEmailInput('');
            setEmails('');
            setSurveyCoverImage('');
            setIsShowSurveySuccessModal(true);
            if (meta?.coverImage && meta?.coverImage?.uploadURL) {
              const uploadImageResponse = await uploadImage(
                surveyCoverImage,
                meta?.coverImage?.uploadURL,
                meta?.coverImage?.filename
              );
              if (uploadImageResponse?.status === 200) {
                successToast('Image uploaded successfully');
                setIsShowSurveySuccessModal(true);
                setTimeout(() => {
                  window.location.href = '/reckit/pulse-survey';
                  setIsShowSurveySuccessModal(false);
                }, 1200);
              }
            }
            setTimeout(() => {
              window.location.href = '/reckit/pulse-survey';
              setIsShowSurveySuccessModal(false);
            }, 1200);
          }
        });
        setErrors('');
      }
    }
  };

  const fetchData = async () => {
    surveyApi
      .getSurveys(1, 10, '', '', '', 'createdAt', surveyId)
      .then((data) => {
        if (data && options?.length > 0) {
          setSurveyData({
            title: data?.data?.data?.title,
            description: data?.data?.data?.description,
            category: options?.find((item) => item?.label === data?.data?.data?.category)?.value
          });

          const questions = [];
          data?.data?.data?.questions?.map((item, index) => {
            const opts = [];
            item?.options?.map((item, i) => {
              opts.push({
                id: item?.id,
                value: item?.value?.value
              });
            });
            questions.push({
              id: item?.id,
              title: item?.title,
              qType: item?.type,
              hasOther: item?.hasOther,
              skipable: item?.skipable,
              previewUrl: item.image,
              nonAbove: false,
              options: opts
            });
          });
          setSurveyQuestions(questions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    surveyId && surveyCategory && fetchData();
  }, [surveyId, surveyCategory]);

  // Edit, Delete and Duplicate Question Actions
  const handleQuestionAction = (type, questionIndex, data) => {
    // let surveyQuestionDataObj = JSON.parse(localStorage.getItem('surveyQuestionDataObj'));
    if (type === 'delete') {
      // setSurveyQuestionImageIndex(surveyQuestionImageIndex - 1);
      const filteredSurveyQuestionDataObj = surveyQuestions.filter(
        (i, index) => index !== questionIndex
      );
      setSurveyQuestions(filteredSurveyQuestionDataObj);
      // surveyQuestionDataObj = {
      //   ...surveyQuestionDataObj[0],
      //   saveQuestionsArray: filteredSurveyQuestionDataObj
      // };
      // setSurveyQuestionDataObj();
      // localStorage.setItem('surveyQuestionDataObj', JSON.stringify([surveyQuestionDataObj]));
    }

    if (type === 'edit') {
      setEditQuestionIndex(questionIndex);
      // setSurveyQuestionImageIndex(surveyQuestionImageIndex - 1);
      setSurveyQuestionData({
        surveyQuestionTitle: data?.title,
        hasOther: data?.hasOther,
        skipable: data?.skipable,
        nonAbove: data?.nonAbove,
        imgFiles: data?.imgFiles,
        previewUrl: data?.previewUrl
      });
      setTempImagePreview(data?.previewUrl);

      setSurveyQuestionDataType(data.qType);
      const newValue = data?.options?.map((i) => ({
        value: i?.value
      }));
      setAnswerChoices(newValue);
      const filteredSurveyQuestionDataObj = surveyQuestions.filter(
        (i, index) => index !== questionIndex
      );
      setSurveyQuestions(filteredSurveyQuestionDataObj);
    }

    if (type === 'duplicate') {
      const duplicateQuestion = surveyQuestions[questionIndex];
      setSurveyQuestions([...surveyQuestions, duplicateQuestion]);
      // surveyQuestionDataObj = {
      //   ...surveyQuestionDataObj[0],
      //   saveQuestionsArray: [...surveyQuestions, duplicateQuestion]
      // };
      // setSurveyQuestionDataObj([surveyQuestionDataObj]);
      // localStorage.setItem('surveyQuestionDataObj', JSON.stringify([surveyQuestionDataObj]));
    }
  };

  useEffect(() => {
    setSurveyQuestionDataObj(JSON.parse(localStorage.getItem('surveyQuestionDataObj')));
  }, [isShowSurveyQuestionDataObj]);

  useEffect(() => {
    handleGetSurveyCategoryData();
    // localStorage.removeItem('surveyQuestionDataObj');
    localStorage.removeItem('bulkAnswer');
    // setSurveyQuestionDataObj('');
    setBulkAnswer('');
  }, []);

  const ProgressBar = ({ label, percentage }) => (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-1">
        <span className="text-lg font-normal">{label}</span>
        <span className="text-sm text-green-600">{percentage}%</span>
      </div>
      <div className="w-full bg-green-100 rounded-full h-2">
        <div className="bg-green-500 h-2 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Survey Monthly Analysis',
        data: [2, 9, 8, 2, 6, 7],
        backgroundColor: '#3a47ab',
        borderColor: ' #4a56db',
        borderWidth: 1
      }
    ]
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Survey Analysis'
      }
    }
  };

  const dataD = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [1135, 365],
        backgroundColor: ['#ec4899', '#f9a8d4'],
        hoverBackgroundColor: ['#db2777', '#f472b6'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  return (
    <>
      {userData.userType !== 4 && <Topbar />}
      <div className="gird font-work-sans mt-[40px] gap-10 add-survey-part pl-5">
        <div className="grid grid-cols-12 justify-around items-center">
          <div className="col-span-6 flex gap-8 sm:gap-12 mt-10 sm:mt-0">
            <div
              className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                showPage === 'edit' ? 'opacity-100' : 'opacity-50'
              }`}
              onClick={() => setShowPage('edit')}
            >
              <button>Edit</button>
            </div>
            <div
              className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                showPage === 'share' ? 'opacity-100' : 'opacity-50'
              }`}
              onClick={() => setShowPage('share')}
            >
              <button>Share</button>
            </div>
            {id && (
              <>
                <div
                  className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                    showPage === 'results' ? 'opacity-100' : 'opacity-50'
                  }`}
                  onClick={() => setShowPage('results')}
                >
                  <button>Results</button>
                </div>
                <div
                  className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                    showPage === 'insights' ? 'opacity-100' : 'opacity-50'
                  }`}
                  onClick={() => setShowPage('insights')}
                >
                  <button>Insights</button>
                </div>
              </>
            )}
          </div>
          <div className="col-span-6 relative sm:top-0 top-[-70px] flex justify-end text-[1rem]">
            <button
              className="border border-black rounded-[30px] px-4 py-2 sm:px-10 sm:py-[8px] truncate text-[14px]"
              onClick={() => handlePublishSurvey(showPage === 'preview' ? 'edit' : 'preview')}
            >
              {showPage === 'preview' ? 'Edit' : 'Preview'} Survey
            </button>
          </div>
        </div>

        {showPage === 'edit' && (
          <div>
            <div className="mt-9 rounded-2xl border-[1.5px] border-shoorah-borderGray bg-[#FFFFFF] p-6">
              <div
                className={`flex flex-row gap-3 justify-center bg-[#FFDC43] cursor-pointer ${
                  previewSurveyLogo
                    ? 'w-20 h-20 rounded-full p-2'
                    : 'w-full max-w-[122px] h-full px-6 py-2 rounded-3xl'
                }`}
              >
                {previewSurveyLogo ? (
                  <img
                    src={previewSurveyLogo}
                    alt="logo-image"
                    className="rounded-full w-full h-full"
                  />
                ) : (
                  <>
                    <label
                      htmlFor="survey-log"
                      className="cursor-pointer flex items-center gap-[10px] font-medium text-[16px] leading-6"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12H16"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16V8"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Logo
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="survey-log"
                      hidden
                      onChange={handleUploadSurveyLogo}
                      disabled={surveyLogo ? true : false}
                    />
                  </>
                )}
              </div>
              <div className="sm:grid sm:grid-cols-12 text-[16px] text-shoorah-lightBlack gap-5 mt-6 sm:w-[80%] add-survey-card">
                <div className="col-span-7">
                  <label htmlFor="title" className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Title
                  </label>
                  <div className="input-field">
                    <input
                      className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                      type="text"
                      name="title"
                      id="title"
                      value={surveyData.title}
                      onChange={handleGetSurveyData}
                      placeholder="Enter title: e.g Social Anxiety Assessment"
                    />
                    {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}
                  </div>
                </div>
                <div className="col-span-5 sm:mt-0 text-[14px] text-shoorah-lightBlack mt-4">
                  <label className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Category
                  </label>
                  <div className="input-field">
                    <Select
                      options={options}
                      name="category"
                      value={options.find((option) => option.value === surveyData.category)}
                      onChange={handleGetSurveyCategory}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '40px',
                          border: '1.5px solid #E7E8EA',
                          backgroundColor: '#F4F4F4',
                          borderRadius: '0.5rem',
                          color: '#0B1D2E'
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: '#fff',
                          borderRadius: '0.5rem'
                        }),
                        option: (baseStyles, { isFocused, isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected ? '' : isFocused ? '#EEEEEE' : '#fff',
                          color: '#0B1D2E',
                          ':hover': {
                            backgroundColor: '#EEEEEE'
                          }
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: '#0B1D2E'
                        })
                      }}
                    />
                    {errors.category && <p className="text-red-500 text-sm">{errors.category}</p>}
                  </div>
                </div>
              </div>
              <div className="text-[16px] text-shoorah-lightBlack mt-6 sm:w-[80%]">
                <label htmlFor="description" className="font-medium text-[16px] leading-9 mb-1">
                  {surveyType === 'Survey' ? 'Survey' : 'Template'} Description
                </label>
                <div className="input-field">
                  <textarea
                    className="w-full block rounded-lg px-4 py-2 text-[14px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray min-h-[100px] resize-none mb-0"
                    value={surveyData.description}
                    name="description"
                    id="description"
                    placeholder="Enter description: e.g Take this assessment to find out if you may have symptoms of social anxiety."
                    onChange={handleGetSurveyData}
                    maxLength={maxLength}
                  ></textarea>
                  {errors.description && (
                    <p className="text-red-500 text-sm">{errors.description}</p>
                  )}
                </div>
              </div>
            </div>
            {surveyQuestions?.length > 0 &&
              surveyQuestions?.map((i, index) => (
                <div
                  key={index}
                  className="px-3 py-10 sm:px-7 sm:py-10 border border-shoorah-borderGray bg-[#FFFFFF] rounded-xl mt-5"
                >
                  <div className="flex justify-center">
                    <div className="flex w-full z-0">
                      <div className="h-10 w-[60px] text-[14px] font-medium leading-9 rounded-lg mr-4 text-shoorah-lightBlack border-[1.5px] border-shoorah-borderGray bg-shoorah-normanGray flex items-center justify-center">
                        Q{index + 1}
                      </div>
                      <div className="question-title flex flex-col">
                        <h2 className="tracking-none sm:tracking-normal h-fit w-[700px] font-medium leading-9 text-[18.5px] sm:text-[24px] mb-6">
                          {i?.title}
                        </h2>
                        {i?.previewUrl && (
                          <img
                            src={i?.previewUrl}
                            alt="preview-image"
                            className="w-[700px] h-[400px] object-cover mb-6"
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative inline-block">
                      <div
                        className="dropdown-toggle justify-end text-shoorah-lightBlack text-[16px] flex items-center rounded-xl cursor-pointer"
                        onClick={() => toggleDropdown(index)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#0B1D2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                            stroke="#0B1D2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                            stroke="#0B1D2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {isOpen && index === dropDownIndex && (
                          <div
                            className="w-40 top-[13px] left-[-131px] z-10 bg-[#FFFFFF] absolute mt-2 border-[1.5px] py-[14px] border-[#DDDDDD] rounded-lg"
                            style={{
                              zIndex: 274
                            }}
                          >
                            <div
                              onClick={() => [
                                setIsOpen(!isOpen),
                                handleQuestionAction('edit', index, i)
                              ]}
                              className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]"
                            >
                              Edit
                            </div>
                            <div
                              onClick={() => [
                                setIsOpen(!isOpen),
                                handleQuestionAction('duplicate', index, i)
                              ]}
                              className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]"
                            >
                              Duplicate
                            </div>
                            <div className="border-b border-gray-400 w-full my-2"></div>
                            <div
                              onClick={() => [
                                setIsOpen(!isOpen),
                                handleQuestionAction('delete', index, i)
                              ]}
                              className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer text-red-700 font-medium leading-6 text-[16px]"
                            >
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {i?.options?.filter((opt) => opt.value !== '')?.length > 0 && (
                    <div className="w-full flex flex-col items-center">
                      <div className="flex flex-col space-y-7 mb-4 w-[90%] justify-center">
                        {i?.options
                          ?.filter((opt) => opt.value !== '')
                          ?.map((opt, index) => (
                            <label className="flex items-center" key={index}>
                              <input type="radio" name="answerRadio" className="w-5 h-5" disabled />
                              <p className="ml-4"> {opt.value} </p>
                            </label>
                          ))}
                        {i?.hasOther && (
                          <div className="title-part flex flex-col">
                            <h1>Other (please specify)</h1>
                            <input
                              className="w-full lg:w-96 mt-3 rounded-lg px-4 py-2 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray"
                              type="text"
                              name="title"
                              readOnly
                            />
                          </div>
                        )}
                        {i?.skipable && (
                          <div className="title-part flex items-center gap-3">
                            <input type="radio" name="answerRadio" className="w-5 h-5" disabled />
                            <h1>Skip</h1>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {i?.qType === 3 && (
                    <div className="relative w-full">
                      <div className="absolute left-4 top-[14px]">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="opacity-50"
                        >
                          <g clipPath="url(#clip0_302_19223)">
                            <path
                              d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                            />
                            <path
                              d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.91711 4.73773V11.2177"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_302_19223">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        maxLength={maxLength}
                        readOnly
                        placeholder="Enter Multiple Choice Question"
                        className="w-full h-[120px] md:w-[89%] lg:w-[80%] rounded-xl p-3 pt-[10px] pl-[40px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray resize-none"
                      />
                      <div className="absolute bottom-5 right-0 md:right-[80px] lg:right-[315px] text-gray-400 text-sm">
                        *{maxLength} Character limit
                      </div>
                    </div>
                  )}
                </div>
              ))}

            <div className="mt-5 rounded-2xl border-[1.5px] border-shoorah-borderGray p-6 bg-[#FFFFFF]">
              <div className="block w-full h-full">
                <h3 className="text-[20px] leading-9 text-shoorah-lightBlack">Add Question</h3>
                <div className="sm:grid sm:grid-cols-12 gap-2 mt-4 items-start w-full">
                  <div className="col-span-1 sm:col-span-7">
                    <div className="input-field">
                      <input
                        className="mb-5 sm:mb-0 w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                        type="text"
                        placeholder="Enter your question"
                        name="surveyQuestionTitle"
                        value={surveyQuestionData.surveyQuestionTitle}
                        onChange={handleGetQuestionData}
                      />
                      {errors.surveyQuestionTitle && (
                        <p className="text-red-500 text-sm">{errors.surveyQuestionTitle}</p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-2 flex gap-5 items-start">
                    <div className="w-10 h-10">
                      {tempImagePreview ? (
                        <img
                          src={tempImagePreview}
                          alt="question-image"
                          className="w-10 h-10 object-cover rounded-lg cursor-pointer"
                        />
                      ) : (
                        <>
                          <label
                            htmlFor="question-image"
                            className="cursor-pointer mb-5 sm:mb-0 flex items-center justify-center border-[1.5px] bg-shoorah-normanGray border-shoorah-borderGray h-full rounded-lg"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="#0B1D2E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                                stroke="#0B1D2E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2.66998 18.9501L7.59998 15.6401C8.38998 15.1101 9.52998 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"
                                stroke="#0B1D2E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </label>
                          <input
                            type="file"
                            id="question-image"
                            hidden
                            accept=".jpg, .jpeg, .png"
                            onChange={handleUploadQuestionImage}
                            disabled={tempImagePreview ? true : false}
                          />
                        </>
                      )}
                    </div>
                    <div className="relative inline-block w-full sm:w-[160px]">
                      <div
                        className="dropdown-toggle border w-full sm:max-w-[160px] bg-shoorah-normanGray text-shoorah-lightBlack text-[16px] border-shoorah-borderGray flex items-center h-10 rounded-[8px] cursor-pointer justify-between px-4"
                        onClick={() => setIsOpenQuestionType(!isOpenQuestionType)}
                      >
                        <span className="text-shoorah-lightBlack text-[12px]">
                          {surveyQuestionDataType === 1
                            ? 'Single Choice'
                            : surveyQuestionDataType === 2
                            ? 'Multiple Choice'
                            : surveyQuestionDataType === 3
                            ? 'Input'
                            : 'Select Type'}
                        </span>
                        <svg
                          className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                      {errors.surveyQuestionType && (
                        <p className="text-red-500 text-sm">{errors.surveyQuestionType}</p>
                      )}
                      {isOpenQuestionType && (
                        <div className="absolute top-full z-40 right-0 w-full bg-white border-[1.5px] border-shoorah-borderGray rounded-xl mt-2 py-3">
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionDataType(1),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Single Choice
                          </div>
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionDataType(2),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Multiple Choice
                          </div>
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionDataType(3),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Input Field
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {(surveyQuestionDataType === 1 || surveyQuestionDataType === 2) && (
                <>
                  <div className="w-full sm:w-[59%] flex justify-between">
                    <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mt-[45px] mb-[10px]">
                      Answers Choices
                    </label>
                  </div>
                  <div className="grid grid-cols-12 sm:gap-4 sm:w-[80%] w-full mb-6">
                    {isShowBulkAnswer
                      ? bulkAnswer?.map((choice, index) => (
                          <div key={index} className="col-span-12 sm:col-span-10 mb-3 sm:mb-0">
                            <div className="flex items-center justify-between gap-3">
                              <input
                                className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                                type="text"
                                placeholder="Enter your answer choice"
                                name="options"
                                value={choice}
                                onChange={(e) => handleGetQueAns(index, e, 'bulk')}
                              />
                              <div className="flex gap-[10px]">
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => addAnswerField('bulk')}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8 12H16"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 16V8"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    if (bulkAnswer.length > 1) {
                                      removeAnswerField(index, 'bulk');
                                    }
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.91998 12H15.92"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {/* <div className="flex gap-4 flex-row">
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => addAnswerField('bulk')}
                                >
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8 12H16"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 16V8"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    if (bulkAnswer.length > 1) {
                                      removeAnswerField(index, 'bulk');
                                    }
                                  }}
                                >
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.91998 12H15.92"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div> */}
                            </div>
                          </div>
                        ))
                      : answerChoices.map((choice, index) => (
                          <div key={index} className="col-span-12 sm:col-span-10  mb-3 sm:mb-0">
                            <div className="flex items-center justify-between gap-5">
                              <div className="input-field w-full">
                                <input
                                  className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                                  type="text"
                                  placeholder="Enter your answer choice"
                                  name="options"
                                  value={choice.value}
                                  onChange={(e) => handleGetQueAns(index, e, 'choice')}
                                />
                                <p className="text-red-500 text-sm">
                                  {errors.answer && errors.answer}
                                </p>
                              </div>
                              <div className="flex gap-[10px]">
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => addAnswerField('choice')}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8 12H16"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 16V8"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    if (surveyQuestionCount > 1) {
                                      removeAnswerField(index, 'choice');
                                    }
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.91998 12H15.92"
                                      stroke="#A1A1A1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                  <div className="flex sm:justify-start rounded-xl flex-col justify-start mb-10">
                    <button
                      className="flex gap-2 justify-center items-center rounded-[25px] border border-[#A1A1A1] px-6 py-[7px] w-fit"
                      onClick={() => {
                        setAddUserPopUpOpen(true);
                      }}
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.50004 14.6666C12.1667 14.6666 15.1667 11.6666 15.1667 7.99998C15.1667 4.33331 12.1667 1.33331 8.50004 1.33331C4.83337 1.33331 1.83337 4.33331 1.83337 7.99998C1.83337 11.6666 4.83337 14.6666 8.50004 14.6666Z"
                          stroke="#0B1D2E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.83337 8H11.1667"
                          stroke="#0B1D2E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 10.6666V5.33331"
                          stroke="#0B1D2E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="text-[14px] text-shoorah-lightBlack font-medium leading-6">
                        Bulk Answers
                      </p>
                    </button>
                    {errors.options && <p className="text-red-500 text-sm">{errors.options}</p>}
                  </div>
                </>
              )}
              {surveyQuestionDataType === 3 && (
                <div className="relative w-full">
                  <div className="absolute left-4 top-[54px]">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="opacity-50"
                    >
                      <g clipPath="url(#clip0_302_19223)">
                        <path
                          d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                        />
                        <path
                          d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.91711 4.73773V11.2177"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_302_19223">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    maxLength={maxLength}
                    readOnly
                    placeholder="Enter Multiple Choice Question"
                    className="w-full h-[290px] md:w-[89%] lg:w-[80%] mt-[41px] mb-[25px] rounded-xl p-3 pt-[10px] pl-[40px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray resize-none"
                  />
                  <div className="absolute bottom-6 right-0 md:right-[80px] lg:right-[290px] py-5 px-6 text-gray-400 text-sm">
                    *{maxLength} Character limit
                  </div>
                </div>
              )}

              {surveyQuestionDataType !== 3 && (
                <>
                  <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mt-5">
                    Answers Choices
                  </label>
                  <div className="w-full sm:overflow-x-auto">
                    <div className="grid grid-cols-12 w-full sm:w-[120%] lg:w-[75%] gap-x-2  mt-2">
                      <div className="col-span-12 sm:col-span-4 px-3 py-[7px] border border-[#CED2D5] rounded-[25px] items-center">
                        <div className="flex items-center gap-[10px]">
                          <input
                            id="other-ans-choice-1"
                            type="checkbox"
                            className="rounded-answer-choice"
                            checked={surveyQuestionData.hasOther}
                            onChange={() =>
                              setSurveyQuestionData((prevData) => ({
                                ...prevData,
                                hasOther: !prevData.hasOther
                              }))
                            }
                          />
                          <label
                            htmlFor="other-ans-choice-1"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Add "Other" Answers choice
                          </label>
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-4 px-3 py-[7px] border border-[#CED2D5] rounded-[25px] items-center">
                        <div className="flex items-center gap-[10px]">
                          <input
                            id="other-ans-choice-2"
                            type="checkbox"
                            className="rounded-answer-choice"
                            checked={surveyQuestionData.skipable}
                            onChange={() =>
                              setSurveyQuestionData((prevData) => ({
                                ...prevData,
                                skipable: !prevData.skipable
                              }))
                            }
                          />
                          <label
                            htmlFor="other-ans-choice-2"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            "Skipable" Question
                          </label>
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-4 px-3 py-[7px] border border-[#CED2D5] rounded-[25px] items-center">
                        <div className="flex items-center gap-[10px]">
                          <input
                            id="other-ans-choice-3"
                            type="checkbox"
                            className="rounded-answer-choice"
                            checked={surveyQuestionData.nonAbove}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setSurveyQuestionData((prevData) => ({
                                ...prevData,
                                nonAbove: isChecked
                              }));
                              setAnswerChoices((prevChoices) =>
                                isChecked
                                  ? [...prevChoices, { value: 'None of the above' }]
                                  : prevChoices.filter(
                                      (choice) => choice.value !== 'None of the above'
                                    )
                              );
                            }}
                          />
                          <label
                            htmlFor="other-ans-choice-3"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            "None of the Above" Options
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors?.questions && (
                      <p className="text-red-500 text-sm">{errors?.questions}</p>
                    )}
                  </div>
                </>
              )}

              <div className="mt-10 flex justify-end">
                <button
                  className="bg-shoorah-secondary text-white px-10 py-2 rounded-full text-[16px] font-medium leading-6"
                  onClick={saveSurveyQuestion}
                >
                  Save Question
                </button>
              </div>
            </div>
            <div className="w-full sm:w-[45%] md:w-[100%] lg:w-[40%]">
              <div className="flex flex-col lg:flex lg:flex-row md:grid md:grid-cols-12 mt-12 mb-[14px] gap-2">
                <div className=" lg:w-[220px] md:col-span-6">
                  <button
                    onClick={() => handlePublishSurvey('publish')}
                    className="w-full h-full flex flex-row px-3 py-[9px] sm:py-0 gap-4 sm:gap-2 md:gap-8 lg:gap-5 justify-center items-center bg-shoorah-secondary text-white rounded-full"
                  >
                    Publish {surveyType === 'Survey' ? 'Survey' : 'Template'}
                    <div className="hidden lg:flex">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.44006 13.2801L10.7867 8.9334C11.3001 8.42006 11.3001 7.58006 10.7867 7.06673L6.44006 2.72006"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className=" lg:w-[160px]  md:col-span-6">
                  <button
                    className="w-full text-black border border-black py-[8px] rounded-full"
                    onClick={() => handlePublishSurvey('draft')}
                  >
                    Save as draft
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {addUserPopUpOpen && (
          <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
            <div className=" h-fit w-[702px] rounded-[24px] px-16 py-4 bg-shoorah-normanGray">
              <div className="text-4xl mt-[16px]">Add Answers in Bulk</div>
              <div className="text-shoorah-lightBlack text-[18px] font-thin mt-[10px] flex">
                Enter each answer choice on a seperate line
              </div>
              <div className="flex justify-center mt-[30px]">
                <div className="relative w-full">
                  <div className="absolute left-4 top-4">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="opacity-50"
                    >
                      <g clipPath="url(#clip0_302_19223)">
                        <path
                          d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                        />
                        <path
                          d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.91711 4.73773V11.2177"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                          stroke="#0B1D2E"
                          strokeWidth="1.428"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_302_19223">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <textarea
                    value={bulkAnswerInput}
                    onChange={(e) => setBulkAnswerInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    maxLength={maxLength}
                    placeholder="Enter reason here..."
                    className="w-full h-[240px] pt-3 pl-12 border rounded-2xl resize-none text-shoorah-lightBlack border-shoorah-borderGray focus:outline-none focus:ring-2 focus:ring-gray-200"
                  />
                  <div className="absolute bottom-2 right-4 py-5 px-6 text-gray-400 text-sm">
                    *{maxLength} Character limit
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-[40px] mb-[10px] gap-6">
                <button
                  className="text-black border border-black px-10  rounded-full p-3"
                  onClick={() => [setAddUserPopUpOpen(false), setBulkAnswerInput('')]}
                >
                  Cancel
                </button>
                <button
                  className="border bg-shoorah-primary text-white border-shoorah-primary px-14 py-2 rounded-full p-5"
                  onClick={handleSaveBulkAnswer}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {showPage === 'share' && (
          <div>
            <div className="rounded-[16px] bg-white border-[1.5px] border-shoorah-borderGray mt-9 p-6">
              <h3 className="text-[20px] leading-9 text-shoorah-lightBlack">Share Survey</h3>
              <div className="team-dropdown mt-6">
                <div className="team-dropdown-menu">
                  <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
                    Select Team
                  </label>
                  <div
                    className="relative flex flex-col justify-between mx-0 items-center px-4 dropdown-menu text-[14px] border-[1.5px] border-shoorah-borderGray w-[80%] sm:w-[40%] h-10 bg-shoorah-normanGray rounded-lg cursor-pointer"
                    onClick={() => setIsOpenShareSurveyTeamMenu(!isOpenShareSurveyTeamMenu)}
                  >
                    <div className="flex flex-row justify-between w-full h-full items-center">
                      <span>
                        {surveyShareData.audience === 1
                          ? 'Business to Customer (B2B)'
                          : surveyShareData.audience === 2
                          ? 'Business to Customer (B2C)'
                          : 'All Team Members' || 'Select Team'}
                      </span>
                      <svg
                        className={`w-4 h-4 transform ${
                          isOpenShareSurveyTeamMenu ? 'rotate-180' : ''
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                    {isOpenShareSurveyTeamMenu && (userType === 0 || userType === 1) && (
                      <div className="absolute top-full z-40 right-0 w-full sm:w-64 bg-white border-[1.5px] border-shoorah-borderGray rounded-[8px] mt-2 py-3">
                        <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                          <input
                            id="B2C"
                            name="audience"
                            type="radio"
                            checked={surveyShareData.audience === 2}
                            className="h-4 w-4 border-black cursor-pointer"
                            value={surveyShareData.audience || 2}
                            onChange={(e) => {
                              const label = 'Business to Customer (B2C)';
                              setSurveyShareData({
                                ...surveyShareData,
                                audience: 2
                              });
                            }}
                          />
                          <label
                            htmlFor="B2C"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Business to Customer (B2C)
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                          <input
                            id="B2B"
                            name="audience"
                            type="radio"
                            checked={surveyShareData.audience === 1}
                            className="h-4 w-4 border-black cursor-pointer"
                            value={surveyShareData.audience || 1}
                            onChange={(e) => {
                              const label = 'Business to Customer (B2B)';
                              setSurveyShareData({
                                ...surveyShareData,
                                audience: 1
                                // selectedLabel: label
                              });
                            }}
                          />
                          <label
                            htmlFor="B2B"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Business to Customer (B2B)
                          </label>
                        </div>
                      </div>
                    )}
                    {isOpenShareSurveyTeamMenu && (userType === 3 || userType === 4) && (
                      <div className="absolute top-full z-40 right-0 w-full sm:w-64 bg-white border-[1.5px] border-shoorah-borderGray rounded-xl mt-2 py-3">
                        {shareSurveyTeamOptions?.map((data, index) => (
                          <div
                            key={index}
                            className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                          >
                            <input
                              id={data}
                              name="audience"
                              type="radio"
                              className="h-4 w-4 border-black cursor-pointer"
                              // value={data}
                              checked
                              onChange={(e) =>
                                setSurveyShareData({
                                  ...surveyShareData,
                                  audience: JSON.parse(e.target.value),
                                  selectedLabel: data
                                })
                              }
                            />
                            <label
                              htmlFor={data}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              {data}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="all-team-check flex items-center gap-2 mt-2">
                  <input
                    name="audience"
                    type="radio"
                    id="all"
                    className="h-4 w-4"
                    checked={surveyShareData.audience === 3}
                    value={userType === 0 || userType === 1 ? 3 : 'all'}
                    onChange={(e) => {
                      const label =
                        userType === 0 || userType === 1 ? 'All Team Members' : 'All Staff Members';
                      setSurveyShareData({
                        ...surveyShareData,
                        audience: 3
                        // selectedLabel: label
                      });
                    }}
                  />
                  <label className="text-[16px] cursor-pointer" htmlFor="all">
                    All {userType === 0 || userType === 1 ? 'Team' : 'Staff'} Members
                  </label>
                </div>
                <p className="text-red-500 text-sm">{errors.audience && errors.audience}</p>
              </div>
              {/* <div className="team-dropdown px-7">
              <div className="team-dropdown-menu">
                <p className="text-[16px]"> Select Team </p>
                <div
                  className="relative flex flex-col justify-between mx-0 items-center px-7 dropdown-menu text-[14px]  border border-shoorah-borderGray w-[80%] sm:w-[40%] h-[40px] bg-shoorah-normanGray rounded-xl mt-[15px] cursor-pointer"
                  onClick={() => setIsOpenShareSurveyTeamMenu(!isOpenShareSurveyTeamMenu)}
                >
                  <div className="flex flex-row justify-between w-full h-full items-center">
                    <span>Select Team</span>
                    <svg
                      className={`w-4 h-4 transform ${
                        isOpenShareSurveyTeamMenu ? 'rotate-180' : ''
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  {isOpenShareSurveyTeamMenu && (userType === 0 || userType === 1) && (
                    <div className="absolute top-full z-40 right-0 w-full sm:w-64 bg-white border-[1.5px] border-shoorah-borderGray rounded-xl mt-2 py-3">
                      <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                        <input
                          id="B2C"
                          name="audience"
                          type="radio"
                          className="h-4 w-4 border-black cursor-pointer"
                          value={surveyShareData.audience || 2}
                          onChange={(e) =>
                            setSurveyShareData({
                              ...surveyShareData,
                              audience: Number(e.target.value)
                            })
                          }
                        />
                        <label
                          htmlFor="B2C"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Business to Customer (B2C)
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                        <input
                          id="B2B"
                          name="audience"
                          type="radio"
                          className="h-4 w-4 border-black cursor-pointer"
                          value={surveyShareData.audience || 1}
                          onChange={(e) =>
                            setSurveyShareData({
                              ...surveyShareData,
                              audience: Number(e.target.value)
                            })
                          }
                        />
                        <label
                          htmlFor="B2B"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Business to Customer (B2B)
                        </label>
                      </div>
                    </div>
                  )}
                  {isOpenShareSurveyTeamMenu && (userType === 3 || userType === 4) && (
                    <div className="absolute top-full z-40 right-0 w-full sm:w-64 bg-white border-[1.5px] border-shoorah-borderGray rounded-xl mt-2 py-3">
                      {shareSurveyTeamOptions?.map((data, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                        >
                          <input
                            id="B2C"
                            name="audience"
                            type="radio"
                            className="h-4 w-4 border-black cursor-pointer"
                            value={data}
                            onChange={(e) =>
                              setSurveyShareData({
                                ...surveyShareData,
                                audience: e.target.value
                              })
                            }
                          />
                          <label
                            htmlFor="B2C"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {data}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="all-team-check flex items-center gap-2 mt-3">
                <input
                  name="audience"
                  type="radio"
                  className="h-4 w-4"
                  value={userType === 0 || userType === 1 ? 3 : 'all'}
                  onChange={(e) =>
                    setSurveyShareData({ ...surveyShareData, audience: e.target.value })
                  }
                />
                <p className="text-[16px]">
                  All {userType === 0 || userType === 1 ? 'Team' : 'Staff'} Members
                </p>
              </div>
              <p className="text-red-500 text-sm">{errors.audience && errors.audience}</p>
            </div> */}
              {(userType === 0 || userType === 1) && (
                <div className="input-field mt-6 w-[80%] sm:w-[40%]">
                  <span>Select Category</span>
                  {/* <Select
                  placeholder="Select Category"
                  options={options}
                  name="category"
                  className="mt-[15px]"
                  onChange={handleGetSurveyCategory}
                /> */}
                  <Select
                    options={options}
                    name="category"
                    onChange={handleGetSurveyCategory}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '40px',
                        border: '1.5px solid #E7E8EA',
                        backgroundColor: '#F4F4F4',
                        borderRadius: '0.5rem',
                        color: '#0B1D2E'
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#F4F4F4',
                        borderRadius: '0.5rem'
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected ? '' : isFocused ? '' : '#F4F4F4',
                        color: '#0B1D2E',
                        ':hover': {
                          backgroundColor: '#EEEEEE'
                        }
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        color: '#0B1D2E'
                      })
                    }}
                  />
                </div>
              )}
              {(userType === 3 || userType === 4) && (
                <div className="flex flex-col w-4/5 sm:w-2/5 mt-6">
                  <label
                    htmlFor="email"
                    className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1"
                  >
                    Add Email
                  </label>
                  <textarea
                    placeholder="Enter email address"
                    id="email"
                    value={emailInput}
                    onChange={handleEmailChange}
                    className="w-full px-4 py-2 h-[80px] text-[14px] resize-none border border-shoorah-borderGray rounded-lg bg-shoorah-normanGray"
                  />
                </div>
              )}
              <div className="mt-6">
                <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
                  Add cover image <span className="font-normal">(Optional)</span>
                </label>
                <div className="flex sm:justify-start justify-center">
                  {coverImg ? (
                    <img
                      src={coverImg}
                      alt="cover-image"
                      className="w-[98%] sm:w-[45.5%] max-w-[360px] h-[188px] rounded-[9px]"
                    />
                  ) : (
                    <div className="w-[98%] sm:w-[45.5%] max-w-[360px] h-[188px] border-[1.5px] border-indigo-300 rounded-[9px] bg-indigo-50 flex flex-col justify-center items-center relative">
                      <div className="w-12 h-12 mb-4">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 44H30C40 44 44 40 44 30V18C44 8 40 4 30 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44Z"
                            fill="#B6C0F3"
                            stroke="#4A56DC"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z"
                            fill="#B6C0F3"
                            stroke="#4A56DC"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33984 37.9L15.1998 31.28C16.7798 30.22 19.0598 30.34 20.4798 31.56L21.1398 32.14C22.6998 33.48 25.2198 33.48 26.7798 32.14L35.0998 25C36.6598 23.66 39.1798 23.66 40.7398 25L43.9998 27.8"
                            fill="#B6C0F3"
                          />
                          <path
                            d="M5.33984 37.9L15.1998 31.28C16.7798 30.22 19.0598 30.34 20.4798 31.56L21.1398 32.14C22.6998 33.48 25.2198 33.48 26.7798 32.14L35.0998 25C36.6598 23.66 39.1798 23.66 40.7398 25L43.9998 27.8"
                            stroke="#4A56DC"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <p className="text-shoorah-lightBlack text-sm leading-[18px] font-normal text-center">
                        Drop your image here, or
                        <label
                          htmlFor="file-upload"
                          className="text-shoorah-gradient cursor-pointer ml-1"
                        >
                          browse
                        </label>
                      </p>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          setSurveyCoverImage(e.target.files[0]);
                          setCoverImgUrl(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                      <p className="text-[8px] leading-3 font-normal text-[#969696] mt-2">
                        Maximum file size: 5MB
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="justify-start mt-[20px] p-6 bg-white rounded-[16px] border-[1.5px] border-shoorah-borderGray">
              <h3 className="text-[20px] leading-9 text-shoorah-lightBlack">
                Publish Time and area <span className="font-light">(Optional)</span>
              </h3>
              <div className="flex flex-col sm:flex-row w-full lg:w-1/2 gap-4 mt-6">
                <div className="w-full sm:w-1/2">
                  <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
                    Add Date
                  </label>
                  <div className="w-[98%] sm:w-[90.5%] max-w-[281px] h-10 border-[1.5px] border-shoorah-borderGray rounded-lg px-4 bg-shoorah-normanGray flex flex-col justify-center items-start relative">
                    <p
                      className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark"
                      onClick={handleToggleCalendar}
                    >
                      {publishDate ? publishDate : 'Set Date'}
                      <svg
                        className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </p>

                    {showCalendar && (
                      <div className="absolute top-[-13px] -left-[40px] md:-left-[22%] lg:left-[150%] xl:-left-[21%] mt-1 bg-white z-50 border-[1.5px] border-shoorah-borderGray rounded-2xl px-2 transform scale-75 text-[14px]">
                        <Calendar
                          className={`calendar dark:!text-white text-sm sm:text-lg`}
                          onChange={handleChangeC}
                          nextLabel={
                            <button className="custom-next-button">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.375 0.75C1.375 0.75 6.625 3.858 6.625 6C6.625 8.14125 1.375 11.25 1.375 11.25"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="dark:text-white"
                                ></path>
                              </svg>
                            </button>
                          }
                          prevLabel={
                            <button className="custom-prev-button">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="rotate-180"
                              >
                                <path
                                  d="M1.375 0.75C1.375 0.75 6.625 3.858 6.625 6C6.625 8.14125 1.375 11.25 1.375 11.25"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="dark:text-white"
                                ></path>
                              </svg>
                            </button>
                          }
                          defaultValue={new Date()}
                          navigationLabel={({ date }) => (
                            <button
                              style={{
                                cursor: 'default',
                                pointerEvents: 'none'
                              }}
                            >
                              {date.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long'
                              })}
                            </button>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full sm:w-1/2">
                  <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
                    Add Time
                  </label>
                  <div className="w-[98%] sm:w-[90.5%] max-w-[281px] h-10 border-[1.5px] border-shoorah-borderGray rounded-lg px-4 bg-shoorah-normanGray flex flex-col justify-center items-start relative cursor-pointer">
                    <div
                      className="w-full h-full flex items-center justify-between text-[14px]"
                      onClick={() => setIsOpenT(!isOpenT)}
                    >
                      <p className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark">
                        {selectedTime ? selectedTime : 'Set Time'}
                        <svg
                          className={`w-4 h-4 transform ${isOpenT ? 'rotate-180' : ''}`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </p>
                    </div>

                    {isOpenT && (
                      <div className="absolute top-[38px] z-20 w-full mt-2 text-[14px] sm:w-[71%] left-0 sm:left-[29%] overflow-hidden bg-white border-[1.5px] border-shoorah-borderGray rounded-xl overflow-y-auto max-h-[200px]">
                        {generateTimes().map((time) => (
                          <div
                            key={time}
                            className=" px-4 sm:px-6 text-[14px] py-2 hover:bg-[#E7E8EA] cursor-pointer"
                            onClick={() => handleSelectT(time)}
                          >
                            {time}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="w-full mt-6">
                <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
                  Survey Area
                </label>
                <div className="w-[98%] sm:w-[90.5%] max-w-[580px] h-10 border-[1.5px] border-shoorah-borderGray rounded-lg px-4 bg-shoorah-normanGray flex flex-col justify-center items-start relative cursor-pointer">
                  <div
                    className="w-full h-full flex items-center justify-between text-[14px]"
                    onClick={() => setIsOpenA(!isOpenA)}
                  >
                    <p className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark">
                      {selectedArea ? selectedArea : 'Set Area'}
                      <svg
                        className={`w-4 h-4 transform ${isOpenA ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.44006 13.2801L10.7867 8.9334C11.3001 8.42006 11.3001 7.58006 10.7867 7.06673L6.44006 2.72006"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </p>
                  </div>

                  {isOpenA && (
                    <div className="absolute top-[38px] z-10 w-[100%] text-[14px] sm:w-[71%] left-0 sm:left-[29%] overflow-hidden bg-white border-[1.5px] border-shoorah-borderGray rounded-xl overflow-y-auto max-h-[200px]">
                      {[].map((area) => (
                        <div
                          key={area}
                          className=" px-4 sm:px-6 text-[14px] py-2 hover:bg-[#E7E8EA] cursor-pointer"
                          onClick={() => handleSelectA(area)}
                        >
                          {area}
                        </div>
                      ))}
                    </div>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="bg-[#FFFFFF] mt-5 rounded-[16px] border-[1.5px] border-shoorah-borderGray p-6">
              <h3 className="text-[20px] leading-9 text-shoorah-lightBlack"> End Page</h3>
              <div className="mt-6">
                <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1">
                  Heading Text
                </label>
                <div className="input-field">
                  <input
                    className="dropdown-menu w-full sm:w-1/2 max-w-[580px] h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                    placeholder="You have completed this survey."
                    name="endHeading"
                    value={surveyShareData.endHeading}
                    onChange={handleGetEndPageData}
                  ></input>
                  <p className="text-red-500 text-sm">{errors.endHeading && errors.endHeading}</p>
                </div>
                <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 mt-6">
                  Body Text
                </label>
                <div className="input-field">
                  <textarea
                    className="block dropdown-menu w-full sm:w-1/2 max-w-[580px] bg-shoorah-normanGray border-[1.5px] text-[14px] resize-none border-shoorah-borderGray rounded-[8px] px-4 py-2 relative"
                    placeholder="Thank you for taking time to answer this survey."
                    name="endBody"
                    value={surveyShareData.endBody}
                    onChange={handleGetEndPageData}
                  ></textarea>
                  <p className="text-red-500 text-sm">{errors.endBody && errors.endBody}</p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 sm:flex justify-end mt-8 gap-2 mb-[14px]">
              <div className="col-span-6 text-[14px] border border-black rounded-3xl w-full sm:max-w-[102px] h-[40px] flex justify-center items-center">
                <button>Preview</button>
              </div>
              <div className="col-span-6">
                <button
                  className="text-[16px] text-white w-full rounded-3xl py-3 px-6 sm:min-w-[150px] h-[40px] bg-shoorah-secondary flex justify-center items-center"
                  onClick={handleSendSurvey}
                >
                  Send Survey
                </button>
              </div>
            </div>

            {addUserPopUpOpenSurvey && (
              <div
                className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10"
                onClick={() => {
                  setAddUserPopUpOpenSurvey(false);
                }}
              >
                <div className=" h-fit w-[695px] rounded-[24px] px-12 py-4 bg-[#FFFFFF] sm:m-0 m-2">
                  <div className="text-4xl mt-[45px] flex justify-center">
                    <svg
                      width="96"
                      height="96"
                      viewBox="0 0 96 96"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="48" cy="48" r="48" fill="#E5EAFA" />
                      <path
                        d="M44.6667 22.5345C46.5067 20.9612 49.52 20.9612 51.3866 22.5345L55.6 26.1614C56.4 26.8548 57.8933 27.4147 58.96 27.4147H63.4933C66.32 27.4147 68.64 29.7345 68.64 32.5612V37.0947C68.64 38.1347 69.2 39.6547 69.8933 40.4547L73.52 44.668C75.0934 46.508 75.0934 49.5213 73.52 51.388L69.8933 55.6012C69.2 56.4012 68.64 57.8946 68.64 58.9612V63.4948C68.64 66.3214 66.32 68.6413 63.4933 68.6413H58.96C57.92 68.6413 56.4 69.2012 55.6 69.8945L51.3866 73.5211C49.5466 75.0945 46.5333 75.0945 44.6667 73.5211L40.4534 69.8945C39.6534 69.2012 38.16 68.6413 37.0933 68.6413H32.48C29.6533 68.6413 27.3333 66.3214 27.3333 63.4948V58.9346C27.3333 57.8946 26.7734 56.4012 26.1067 55.6012L22.5067 51.3613C20.96 49.5213 20.96 46.5346 22.5067 44.6946L26.1067 40.4547C26.7734 39.6547 27.3333 38.1614 27.3333 37.1214V32.5612C27.3333 29.7345 29.6533 27.4147 32.48 27.4147H37.0933C38.1334 27.4147 39.6534 26.8548 40.4534 26.1614L44.6667 22.5345Z"
                        fill="#B6C0F3"
                        stroke="#4A57DA"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M42.3737 55.5193L46.2403 58.506C46.747 59.0126 47.867 59.2527 48.6137 59.2527H53.3337C54.827 59.2527 56.4537 58.1328 56.827 56.6394L59.8136 47.5193C60.427 45.786 59.307 44.2661 57.4403 44.2661H52.4537C51.707 44.2661 51.0937 43.6529 51.2004 42.7729L51.8136 38.7729C52.0536 37.6529 51.307 36.3995 50.187 36.0261C49.2003 35.6528 47.947 36.1594 47.4403 36.906L42.3203 44.506"
                        stroke="#4A57DA"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M36 55.5182V43.305C36 41.545 36.7467 40.9316 38.5067 40.9316H39.76C41.4933 40.9316 42.2667 41.545 42.2667 43.305V55.5182C42.2667 57.2516 41.52 57.8916 39.76 57.8916H38.5067C36.7467 57.8916 36 57.2782 36 55.5182Z"
                        stroke="#4A57DA"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-shoorah-lightBlack text-[24px]  mt-[30px] flex justify-center">
                    <div className="w-[75%] text-center">
                      Congratulations on successfully creating your first survey template.
                    </div>
                  </div>
                  <div className="flex justify-center mt-[18px]">
                    <div className="relative w-[50%] text-[16px] text-shoorah-lightBlack flex justify-center text-center">
                      <p className="font-thin">
                        {' '}
                        You can now manage this survey and access valuable insights
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[40px] mb-[50px] gap-6">
                    <button
                      className="text-[#FFFFFF] bg-shoorah-secondary text-[16px] border border-black px-10 rounded-full p-3"
                      onClick={() => {
                        setAddUserPopUpOpen(false);
                      }}
                    >
                      Template
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {showPage === 'preview' && (
          <div>
            <div className="mt-9 rounded-2xl border-[1.5px] border-shoorah-borderGray bg-[#FFFFFF] p-6">
              <div
                className={`flex flex-row gap-3 justify-center bg-[#FFDC43] cursor-pointer ${
                  previewSurveyLogo
                    ? 'w-20 h-20 rounded-full p-2'
                    : 'w-full max-w-[122px] h-full px-6 py-2 rounded-3xl'
                }`}
              >
                {previewSurveyLogo ? (
                  <img
                    src={previewSurveyLogo}
                    alt="logo-image"
                    className="rounded-full w-full h-full"
                  />
                ) : (
                  <>
                    <label
                      htmlFor="survey-log"
                      className="pointer-events-none flex items-center gap-[10px] font-medium text-[16px] leading-6"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12H16"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16V8"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Logo
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="survey-log"
                      hidden
                      onChange={handleUploadSurveyLogo}
                      readOnly
                    />
                  </>
                )}
              </div>
              <div className="sm:grid sm:grid-cols-12 text-[16px] text-shoorah-lightBlack gap-5 mt-6 sm:w-[80%] add-survey-card">
                <div className="col-span-7">
                  <label htmlFor="title" className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Title
                  </label>
                  <div className="input-field">
                    <input
                      className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                      type="text"
                      name="title"
                      readOnly
                      id="title"
                      value={surveyData.title}
                      onChange={handleGetSurveyData}
                      placeholder="Enter title: e.g Social Anxiety Assessment"
                    />
                    {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}
                  </div>
                </div>
                <div className="col-span-5 sm:mt-0 text-[14px] text-shoorah-lightBlack mt-4">
                  <label className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Category
                  </label>
                  <div className="input-field">
                    <Select
                      options={options}
                      name="category"
                      isDisabled={true}
                      value={options.find((option) => option.value === surveyData.category)}
                      onChange={handleGetSurveyCategory}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '40px',
                          border: '1.5px solid #E7E8EA',
                          backgroundColor: '#F4F4F4',
                          borderRadius: '0.5rem',
                          color: '#0B1D2E'
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: '#fff',
                          borderRadius: '0.5rem'
                        }),
                        option: (baseStyles, { isFocused, isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected ? '' : isFocused ? '#EEEEEE' : '#fff',
                          color: '#0B1D2E',
                          ':hover': {
                            backgroundColor: '#EEEEEE'
                          }
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: '#0B1D2E'
                        })
                      }}
                    />
                    {errors.category && <p className="text-red-500 text-sm">{errors.category}</p>}
                  </div>
                </div>
              </div>
              <div className="text-[16px] text-shoorah-lightBlack mt-6 sm:w-[80%]">
                <label htmlFor="description" className="font-medium text-[16px] leading-9 mb-1">
                  {surveyType === 'Survey' ? 'Survey' : 'Template'} Description
                </label>
                <div className="input-field">
                  <textarea
                    className="w-full block rounded-lg px-4 py-2 text-[14px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray min-h-[100px] resize-none mb-0"
                    value={surveyData.description}
                    name="description"
                    id="description"
                    readOnly
                    placeholder="Enter description: e.g Take this assessment to find out if you may have symptoms of social anxiety."
                    onChange={handleGetSurveyData}
                    maxLength={maxLength}
                  ></textarea>
                  {errors.description && (
                    <p className="text-red-500 text-sm">{errors.description}</p>
                  )}
                </div>
              </div>
            </div>
            {surveyQuestions?.length > 0 &&
              surveyQuestions?.map((i, index) => (
                <div
                  key={index}
                  className="px-3 py-10 sm:px-7 sm:py-10 border border-shoorah-borderGray bg-[#FFFFFF] rounded-xl mt-5"
                >
                  <div className="flex justify-center">
                    <div className="flex w-full z-0">
                      <div className="h-10 w-[60px] text-[14px] font-medium leading-9 rounded-lg mr-4 text-shoorah-lightBlack border-[1.5px] border-shoorah-borderGray bg-shoorah-normanGray flex items-center justify-center">
                        Q{index + 1}
                      </div>
                      <div className="question-title flex flex-col">
                        <h2 className="tracking-none sm:tracking-normal h-fit w-[700px] font-medium leading-9 text-[18.5px] sm:text-[24px] mb-6">
                          {i?.title}
                        </h2>
                        {i?.previewUrl && (
                          <img
                            src={i?.previewUrl}
                            alt="preview-image"
                            className="w-[700px] h-[400px] object-cover mb-6"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {i?.options?.filter((opt) => opt.value !== '')?.length > 0 && (
                    <div className="w-full flex flex-col items-center">
                      <div className="flex flex-col space-y-7 mb-4 w-[90%] justify-center">
                        {i?.options
                          ?.filter((opt) => opt.value !== '')
                          ?.map((opt, index) => (
                            <label className="flex items-center" key={index}>
                              <input type="radio" name="answerRadio" className="w-5 h-5" disabled />
                              <p className="ml-4"> {opt.value} </p>
                            </label>
                          ))}
                        {i?.hasOther && (
                          <div className="title-part flex flex-col">
                            <h1>Other (please specify)</h1>
                            <input
                              className="w-full lg:w-96 mt-3 rounded-lg px-4 py-2 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray"
                              type="text"
                              name="title"
                              readOnly
                            />
                          </div>
                        )}
                        {i?.skipable && (
                          <div className="title-part flex items-center gap-3">
                            <input type="radio" name="answerRadio" className="w-5 h-5" disabled />
                            <h1>Skip</h1>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {i?.qType === 3 && (
                    <div className="relative w-full">
                      <div className="absolute left-4 top-[14px]">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="opacity-50"
                        >
                          <g clipPath="url(#clip0_302_19223)">
                            <path
                              d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                            />
                            <path
                              d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.91711 4.73773V11.2177"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_302_19223">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        maxLength={maxLength}
                        readOnly
                        placeholder="Enter Multiple Choice Question"
                        className="w-full h-[120px] md:w-[89%] lg:w-[80%] rounded-xl p-3 pt-[10px] pl-[40px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray resize-none"
                      />
                      <div className="absolute bottom-5 right-0 md:right-[80px] lg:right-[315px] text-gray-400 text-sm">
                        *{maxLength} Character limit
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div className="w-full sm:w-[45%] md:w-[100%] lg:w-[40%]">
              <div className="flex flex-row sm:grid sm:grid-cols-12 mt-[20px] gap-2 sm:gap-5">
                <div className="col-span-12 sm:col-span-6">
                  <button
                    className="bg-shoorah-secondary text-white px-6 py-2 rounded-full text-[16px] font-medium leading-6 flex gap-3 items-center"
                    onClick={handleSubmitSurvey}
                  >
                    Submit {surveyType === 'Survey' ? 'Survey' : 'Template'}
                    <div className="sm:hidden lg:flex">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.44006 13.2801L10.7867 8.9334C11.3001 8.42006 11.3001 7.58006 10.7867 7.06673L6.44006 2.72006"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showPage === 'insights' && (
          <div className="p-4 ml-1 w-full h-[330px] mb-10 font-work-sans">
            <div className="flex flex-col sm:flex-row gap-4 w-full">
              <div className="flex flex-col lg:flex-row md:flex-col gap-4 w-full">
                <div className="flex flex-col">
                  <div className="p-4 w-full md:w-full lg:min-w-[320px] lg:max-w-[420px] bg-[#FFFFFF] rounded-[10px] border border-[#E7E8EA]">
                    <h2 className="text-xl font-medium text-[#0B0F18] text-left pl-[0px] lg:pl-[10px] md:pl-[0px]">
                      Overall Responses
                    </h2>
                    <div className="relative flex max-h-[180px] justify-center items-center">
                      <div className="absolute text-center">
                        <p className="text-2xl text-[#0B0F18] ">1,200</p>
                        <p className="text-sm font-thin text-[#606060]">Total</p>
                      </div>
                      <Doughnut data={dataD} options={optionsD} />
                    </div>
                    <div className="flex flex-row md:flex-row lg:flex-col md:justify-between md:gap-20 lg:gap-3 gap-8 lg:pl-2 lg:pr-3 md:pl-20 md:pr-20">
                      <div className="flex items-center justify-left">
                        <span className="w-3 h-3 bg-pink-500 rounded-full mr-2"></span>
                        <p className="flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                          Responses: <p className="text-[#0B0F18]">1,135 </p>
                        </p>
                      </div>
                      <div className="flex items-center justify-left">
                        <span className="w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
                        <p className="flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                          No Response: <p className="text-[#0B0F18]">365</p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex md-w-full flex-col gap-6">
                  <div className="md:w-full min-w-[320px] lg:min-w-[370px] h-[166px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] px-4 py-2">
                    <div className="flex flex-col justify-between font-work-sans md:pl-5 md:pr-5 lg:px-0 mt-1 md:px-5">
                      <p className="text-[19px] font-medium">Average Completion Time</p>
                      <p className="text-[58px] font-medium">
                        8 <span className=" mr-2 text-[58px] font-medium"> minutes </span>
                      </p>
                      <div className="flex flex-row justify-between">
                        <p className="text-green-400"> 25%</p>
                        <p className="text-gray-400"> Last 7 days</p>
                      </div>
                    </div>
                  </div>

                  <div className="md:w-full min-w-[320px] lg:min-w-[370px] h-[111px] bg-white rounded-[10px] border-[1.5px] border-[#E7E8EA] md:px-5 p-5">
                    <div className="flex flex-row justify-between">
                      <p className="text-[20px]">Completion Rate</p>
                    </div>
                    <ProgressBar label="" percentage={71.5} />
                  </div>
                </div>
                <div className=" w-full h-[300px] bg-white border-[1.5px] border-[#E7E8EA] rounded-[10px] p-4">
                  <div className="flex flex-col justify-between px-2">
                    <p className=" text-[20px] text-[#0B0F18]">Top Performaing Template</p>
                    <div className="w-[98%] pb-2 mt-2 bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-[18px]">
                      <div className="flex flex-col rounded-t-[18px]">
                        <div className="flex h-[130px] bg-[#F4F4F4] rounded-t-[16.5px]">
                          <img
                            src={slack}
                            alt="survey"
                            className="object-scale-down w-auto h-auto"
                          />
                        </div>
                        <div className="h-fit flex flex-col gap-2 mx-4 mt-[8px]">
                          <div>
                            <div className="flex flex-row justify-between items-center mx-2 mb-2 bg-[#FFFFFF] relative">
                              <p className="text-[20px] mb-2 text-shoorah-lightBlack font-semibold">
                                Company Wide Template
                              </p>
                              <button>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                    stroke="#0B1D2E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                                    stroke="#0B1D2E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                                    stroke="#0B1D2E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>

                            <div>
                              <p className="text-[16px] text-shoorah-lightBlack font-extralight mx-2">
                                Used 1,345 times
                              </p>
                            </div>
                          </div>
                          <div className="flex ">
                            <p className="text-[16px] text-shoorah-lightBlack font-light"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col  lg:flex-row md:flex-col gap-6 sm:w-full mt-[20px]">
              <div className=" flex flex-col justify-between w-full md:w-full  lg:max-w-[265px] h-[200px] md:h-[200px] lg:h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
                <p className=" text-[20px] lg:text-[20px]  md:text-[25px] leading-tight h-[66px]  font-medium  w-[80%] justify-start text-[#0B0F18]">
                  Percentage of incomplete Assessments
                </p>
                <p className=" text-[64px] text-[#0B0F18] font-work-sans">35 %</p>
              </div>
              <div className="  flex flex-col justify-between  w-full md:w-full  lg:max-w-[265px]  h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
                <p className="text-[#0B0F18] text-[20px]   lg:text-[20px]  md:text-[25px]">
                  Drop-off point
                </p>
                <div>
                  <div className=" max-w-md lg:max-w-md  mx-auto p-6 ">
                    <ProgressBar label="Intro" percentage={7} />
                    <ProgressBar label="About" percentage={55} />
                    <ProgressBar label="Questions" percentage={89} />
                  </div>
                </div>
              </div>
              <div className=" h-[344px]  w-full  bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-4">
                <Bar
                  data={data}
                  options={{
                    ...options2,
                    responsive: true,
                    maintainAspectRatio: false
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {isShowSurveyApprovalModal && (
          <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
            <div className=" h-fit w-[692px] rounded-[24px] px-24 py-16 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray">
              <svg
                width={96}
                height={96}
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto"
              >
                <circle cx={48} cy={48} r={48} fill="#E5EAFA" />
                <path
                  d="M44.6667 22.5341C46.5067 20.9608 49.52 20.9608 51.3866 22.5341L55.6 26.1611C56.4 26.8544 57.8933 27.4143 58.96 27.4143H63.4933C66.32 27.4143 68.64 29.7342 68.64 32.5608V37.0943C68.64 38.1343 69.2 39.6544 69.8933 40.4544L73.52 44.6676C75.0934 46.5076 75.0934 49.521 73.52 51.3876L69.8933 55.6009C69.2 56.4009 68.64 57.8942 68.64 58.9609V63.4944C68.64 66.3211 66.32 68.6409 63.4933 68.6409H58.96C57.92 68.6409 56.4 69.2008 55.6 69.8941L51.3866 73.5208C49.5466 75.0941 46.5333 75.0941 44.6667 73.5208L40.4534 69.8941C39.6534 69.2008 38.16 68.6409 37.0933 68.6409H32.48C29.6533 68.6409 27.3333 66.3211 27.3333 63.4944V58.9342C27.3333 57.8942 26.7734 56.4009 26.1067 55.6009L22.5067 51.3609C20.96 49.5209 20.96 46.5343 22.5067 44.6943L26.1067 40.4544C26.7734 39.6544 27.3333 38.161 27.3333 37.121V32.5608C27.3333 29.7342 29.6533 27.4143 32.48 27.4143H37.0933C38.1334 27.4143 39.6534 26.8544 40.4534 26.1611L44.6667 22.5341Z"
                  fill="#B6C0F3"
                  stroke="#4A57DA"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42.3737 55.5196L46.2403 58.5062C46.747 59.0129 47.867 59.253 48.6137 59.253H53.3337C54.827 59.253 56.4537 58.133 56.827 56.6397L59.8136 47.5196C60.427 45.7862 59.307 44.2663 57.4403 44.2663H52.4537C51.707 44.2663 51.0937 43.6531 51.2004 42.7731L51.8136 38.7731C52.0536 37.6531 51.307 36.3997 50.187 36.0264C49.2003 35.6531 47.947 36.1596 47.4403 36.9063L42.3203 44.5062"
                  stroke="#4A57DA"
                  strokeWidth={3}
                  strokeMiterlimit={10}
                />
                <path
                  d="M36 55.5185V43.3053C36 41.5453 36.7467 40.9319 38.5067 40.9319H39.76C41.4933 40.9319 42.2667 41.5453 42.2667 43.3053V55.5185C42.2667 57.2518 41.52 57.8918 39.76 57.8918H38.5067C36.7467 57.8918 36 57.2785 36 55.5185Z"
                  stroke="#4A57DA"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h1 className="text-2xl font-medium text-center mt-6">
                You're currently unable to share this survey
              </h1>
              <p className="text-base font-normal text-center mt-4">
                Send the survey to an admin to gain approval
              </p>
              <Select
                options={superAdminListOptions}
                className="mt-4 w-96 mx-auto"
                placeholder="Select Admin..."
                onChange={(e) => setSurveyShareData({ ...surveyShareData, approvalBy: e.value })}
              />
              <button
                className="bg-[#4A57DA] rounded-full px-10 py-3 text-white text-lg block mx-auto mt-8"
                onClick={handleSendSurveyWithApproval}
              >
                My Surveys
              </button>
            </div>
          </div>
        )}

        {isShowSurveySuccessModal && (
          <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
            <div className=" h-fit w-[692px] rounded-[24px] px-24 py-16 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray">
              <svg
                width={96}
                height={96}
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto"
              >
                <circle cx={48} cy={48} r={48} fill="#E5EAFA" />
                <path
                  d="M44.6667 22.5341C46.5067 20.9608 49.52 20.9608 51.3866 22.5341L55.6 26.1611C56.4 26.8544 57.8933 27.4143 58.96 27.4143H63.4933C66.32 27.4143 68.64 29.7342 68.64 32.5608V37.0943C68.64 38.1343 69.2 39.6544 69.8933 40.4544L73.52 44.6676C75.0934 46.5076 75.0934 49.521 73.52 51.3876L69.8933 55.6009C69.2 56.4009 68.64 57.8942 68.64 58.9609V63.4944C68.64 66.3211 66.32 68.6409 63.4933 68.6409H58.96C57.92 68.6409 56.4 69.2008 55.6 69.8941L51.3866 73.5208C49.5466 75.0941 46.5333 75.0941 44.6667 73.5208L40.4534 69.8941C39.6534 69.2008 38.16 68.6409 37.0933 68.6409H32.48C29.6533 68.6409 27.3333 66.3211 27.3333 63.4944V58.9342C27.3333 57.8942 26.7734 56.4009 26.1067 55.6009L22.5067 51.3609C20.96 49.5209 20.96 46.5343 22.5067 44.6943L26.1067 40.4544C26.7734 39.6544 27.3333 38.161 27.3333 37.121V32.5608C27.3333 29.7342 29.6533 27.4143 32.48 27.4143H37.0933C38.1334 27.4143 39.6534 26.8544 40.4534 26.1611L44.6667 22.5341Z"
                  fill="#B6C0F3"
                  stroke="#4A57DA"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42.3737 55.5196L46.2403 58.5062C46.747 59.0129 47.867 59.253 48.6137 59.253H53.3337C54.827 59.253 56.4537 58.133 56.827 56.6397L59.8136 47.5196C60.427 45.7862 59.307 44.2663 57.4403 44.2663H52.4537C51.707 44.2663 51.0937 43.6531 51.2004 42.7731L51.8136 38.7731C52.0536 37.6531 51.307 36.3997 50.187 36.0264C49.2003 35.6531 47.947 36.1596 47.4403 36.9063L42.3203 44.5062"
                  stroke="#4A57DA"
                  strokeWidth={3}
                  strokeMiterlimit={10}
                />
                <path
                  d="M36 55.5185V43.3053C36 41.5453 36.7467 40.9319 38.5067 40.9319H39.76C41.4933 40.9319 42.2667 41.5453 42.2667 43.3053V55.5185C42.2667 57.2518 41.52 57.8918 39.76 57.8918H38.5067C36.7467 57.8918 36 57.2785 36 55.5185Z"
                  stroke="#4A57DA"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h1 className="text-2xl font-medium text-center mt-6">
                Congratulation on successfully sending you first survey to your staff!
              </h1>
              <p className="text-base font-normal text-center mt-4">
                You can now manage this survey and access valuable insights
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default AddEditPulseSurvey;
